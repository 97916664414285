<template>
  <SubmitModal ref="modal" :submit="submit">
    <template slot="header">Powtórz rachunek</template>
    <template slot="submit">Wygeneruj</template>

    <div slot="body" slot-scope="{ item }">
      <p>Powtarzaj rachunek {{ item.bill.name }} na kwotę {{ item.bill.cost }} zł:</p>

      <section>
        <label>
          <span>Powtarzaj</span>
          <select v-model="frequency">
            <option v-for="item in REPEAT_OPTIONS" :key="item.value" :value="item.value">{{ item.label }}</option>
          </select>
        </label>

        <label>
          <span>Co który {{ repeatCountLabel }}?</span>
          <input type="number" v-model="step" />
        </label>

        <label>
          <span>Od</span>
          <date-picker :start-date="startDate" @select="startDate = arguments[0]"/>
        </label>

        <label class="radio">
          <input type="radio" v-model="condition" value="limit"/>
          <span>Określoną liczbę razy</span>
        </label>

        <label class="radio">
          <input type="radio" v-model="condition" value="endDate"/>
          <span>Do określonej daty</span>
        </label>

        <label v-if="condition === 'limit'">
          <span>Liczba wystąpień</span>
          <input type="number" v-model="limit"/>
        </label>

        <label v-if="condition === 'endDate'">
          <span>Do</span>
          <date-picker :start-date="endDate" @select="endDate = arguments[0]"/>
        </label>
      </section>
    </div>
  </SubmitModal>
</template>

<script>
  import SubmitModal from '../Modal/SubmitModal';
  import moment from 'moment';
  import DatePicker from '@components/DatePicker/DatePicker';

  const Frequency = {
    month: {
      repeatLabel: 'Miesięcznie',
      repeatCount: 'miesiąc'
    },
    week: {
      repeatLabel: 'Tygodniowo',
      repeatCount: 'tydzień'
    },
    day: {
      repeatLabel: 'Dziennie',
      repeatCount: 'dzień'
    },
    year: {
      repeatLabel: 'Rocznie',
      repeatCount: 'rok'
    }
  };

  export default {
    inject: [
      '$api'
    ],

    components: {
      DatePicker,
      SubmitModal
    },

    data() {
      return {
        billId: null,
        step: 1,
        frequency: 'month',
        startDate: moment.utc(),
        endDate: moment.utc().add(1, 'days'),
        limit: 1,
        condition: 'limit'
      };
    },

    created() {
      this.REPEAT_OPTIONS = Object.entries(Frequency)
        .map(([key, value]) => ({
          value: key,
          label: value.repeatLabel
        }));
    },

    methods: {
      show(bill) {
        this.billId = bill.id;

        return this.$refs.modal.show({
          bill
        });
      },

      async submit() {
        return this.$api.repeatBill(this.billId, {
          step: this.step,
          frequency: this.frequency,
          startDate: this.startDate,
          endDate: this.endDate,
          limit: this.limit,
          condition: this.condition
        });
      }
    },

    computed: {
      repeatCountLabel() {
        return Frequency[this.frequency].repeatCount;
      }
    }
  };
</script>
