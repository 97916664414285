<template>
  <auto-complete ref="input"
                 :selected-item="selected"
                 :auto-focus="autoFocus"
                 :items="items"
                 :allow-not-found="allowNotFound"
                 :placeholder="placeholder"
                 :select-first="selectFirst"
                 @select="$emit('select', arguments[0].name, arguments[1])"
                 @select-new="$emit('select-new', arguments[0], arguments[1])"
                 @select-empty="$emit('select-empty', arguments[0])"
                 @not-found="$emit('not-found', arguments[0], arguments[1])">
  </auto-complete>
</template>

<script>
  import AutoComplete from '../AutoComplete/AutoComplete';

  function toAutoCompleteItem(product) {
    return {
      data: product,
      order: product.occurence,
      label: product.name
    };
  }

  export default {
    name: 'product-autocomplete',

    inject: ['$api'],

    components: {
      AutoComplete
    },

    props: {
      selectFirst: {
        default: true
      },
      allowNotFound: {
        default: true
      },
      placeholder: {
        default: ''
      },
      product: {
        default: 'Zbiorczy'
      },
      autoFocus: {
        default: true
      }
    },

    data() {
      return {
        list: []
      };
    },

    computed: {
      items() {
        return this.list.map(toAutoCompleteItem);
      },

      selected() {
        return this.product ? toAutoCompleteItem({
          name: this.product
        }) : null;
      }
    },

    created() {
      this.$api.getProductsList()
        .then((products) => (this.list = products));
    }
  };
</script>
