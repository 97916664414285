<template>
  <ItemSummary
    :name="shop"
    :description="address"
    :amount="receipt.total"
    :quantity="count"
    :tags="tags"
  />
</template>

<script>
  import ItemSummary from '@components/List/ItemSummary';

  export default {
    components: { ItemSummary },
    props: ['receipt'],

    computed: {
      shop() {
        return this.receipt && this.receipt.shop && this.receipt.shop.name;
      },

      address() {
        if (!this.receipt || !this.receipt.shop) {
          return '';
        }

        const { shop } = this.receipt;

        if (shop.address && shop.city) {
          return `${shop.address}, ${shop.city}`;
        }

        return shop.address || shop.city;
      },

      count() {
        return `Produktów: ${(this.receipt && this.receipt.productCount) || 0}`;
      },

      tags() {
        return this.receipt.products.reduce((summary, product) => {
          product.tags.forEach((tag) => {
            if (!summary.map[tag.id]) {
              summary.map[tag.id] = true;
              summary.list.push(tag);
            }
          });

          return summary;
        }, {
          map: {},
          list: []
        }).list;
      }
    }
  };
</script>
