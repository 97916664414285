<template>
  <receipt
    title="Edycja paragonu"
    save-button="Zapisz zmiany"
    :receipt="receipt"
    :ready="ready"
    @receipt-deleted="onDelete"
    @receipt-saved="onSave"
    @receipt-not-saved="onError"
    @receipt-failed-to-delete="onError"
  ></receipt>
</template>

<script>
  import Receipt from '../Receipt/Receipt';
  import MessageMixin from '../../mixins/MessageMixin';

  export default {
    name: 'edit-receipt',

    inject: [
      '$api'
    ],

    mixins: [
      MessageMixin
    ],

    components: {
      Receipt
    },

    data() {
      return {
        ready: false,
        receipt: null
      };
    },

    created() {
      this.ready = false;
      this.$api.getReceipt(this.$route.params.id)
        .then((receipt) => (this.receipt = receipt))
        .catch((err) => this.onError(err))
        .then(() => (this.ready = true));
    },

    methods: {
      onSave() {
        this.showSuccessMessage('Zapisano zmiany');
      },

      onError(err) {
        this.showFailMessage(err.message);
      },

      onDelete() {
        this.showSuccessMessage('Paragon został skasowany');
        this.$router.push('/receipts');
      }
    }
  };
</script>
