<template>
    <li class="auto-complete-item shop-input-item">
      <strong>{{ item.name }}</strong>
      {{ item.address }}
    </li>
</template>

<script>
  export default {
    name: 'shop-input-item',
    props: {
      item: Object
    }
  };
</script>

<style lang="scss">
  .shop-input-item {
    font-size: 0.8em;

    strong {
      font-size: 1.2em;
      display: block;
    }
  }
</style>
