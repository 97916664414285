<template>
  <SubmitModal ref="modal" :submit="remove">
    <template slot="header">Uwaga!</template>
    <template slot="cancel">Nie</template>
    <template slot="submit">Tak, usuń</template>
    <div slot="body" slot-scope="{ item }">
      <p>Czy na pewno chcesz usunąć ten wpływ?</p>

      <div class="expense-wrapper">
        <IncomeSummary :income="item"/>
      </div>
    </div>
  </SubmitModal>
</template>

<script>
  import SubmitModal from '@components/Modal/SubmitModal';
  import IncomeSummary from '@components/Income/IncomeSummary';

  export default {
    inject: [
      '$api'
    ],

    components: {
      IncomeSummary,
      SubmitModal
    },

    methods: {
      show(item) {
        return this.$refs.modal.show(item);
      },

      remove(item) {
        return this.$api.deleteIncome(item.id);
      }
    }
  };
</script>
