<template>
  <ListItem class="tag-item">
    <template slot="summary">
      <TagSummary :tag="tag"/>
    </template>

    <template slot="actions">
      <EditItemAction @click.native="edit"/>
      <RemoveItemAction @click.native="remove"/>
    </template>
  </ListItem>
</template>

<script>
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import ListItem from '@components/List/ListItem';
  // import { Modals } from '@components/consts';
  import TagSummary from '@components/Tags/TagSummary';
  import { Modals } from '@components/consts';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage',
      'showFailMessage'
    ],

    components: {
      TagSummary,
      ListItem,
      RemoveItemAction,
      EditItemAction
    },

    props: {
      tag: {
        type: Object
      }
    },

    methods: {
      edit() {
        this.showModal(Modals.TAG_FORM, this.tag)
          .then((result) => {
            if (result) {
              Object.assign(this.tag, result);
              this.showSuccessMessage(`Zaktualizowano tag ${result.name}`);
              this.$emit('update', result);
            }
          });
      },

      remove() {
        this.showFailMessage('Not implemented');
      }
    }
  };
</script>
