<template>
  <div id="new-receipt-form">
    <h4>Dodaj paragon</h4>

    <div>
      <div v-if="loading">
        <p class="loading">
          <i class="fa fa-spin fa-spinner"></i>
          Ładowanie...
        </p>
      </div>

      <div class="new-receipt-form" v-if="shopsList && shopsList.length">
        <form class="new-receipt-meta" @submit.prevent="checkBeforeSubmit()">
          <shop-input ref="shop"
                      :auto-focus="true"
                      :shop="shop"
                      :shops="shopsList"
                      @select="setShop"
                      @not-found="createShop"/>
          <date-picker ref="date" :start-date="date" @select="setDate"/>
          <product-autocomplete :auto-focus="false"
                                :product="name"
                                @select="name = arguments[0]"
                                @select-new="name = arguments[0]"/>
          <AmountInput :amount.sync="total"/>
          <tag-input :tags="tags" @change="tags = arguments[0]" @keydown.native.tab="showReceiptDetails($event)"/>

          <div class="submit-placeholder">
            <spinning-button class="submit" ref="button" :loading="saving">Dodaj</spinning-button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<style lang="scss">
  .submit-placeholder {
    margin-top: 20px;
    text-align: right;
  }
</style>

<script>
  import { Modals } from '@components/consts';
  import ShopInput from '../ShopInput/ShopInput';
  import DatePicker from '../DatePicker/DatePicker';
  import SpinningButton from '../SpinningButton/SpinningButton';
  import moment from 'moment';
  import ShopListMixin from '../../mixins/ShopListMixin';
  import MessageMixin from '../../mixins/MessageMixin';
  import ProductAutocomplete from '../ProductAutocomplete/ProductAutocomplete';
  import TagInput from '../TagInput/TagInput.vue';
  import AmountInput from '../Inputs/AmountInput';

  const MIN_COST = 0.01;

  export default {
    inject: [
      '$api',
      'showModal'
    ],

    mixins: [
      ShopListMixin,
      MessageMixin
    ],

    components: {
      AmountInput,
      TagInput,
      ProductAutocomplete,
      ShopInput,
      DatePicker,
      SpinningButton
    },

    data() {
      return {
        loading: false,
        saving: false,
        name: 'Zbiorczy',
        tags: '',
        total: 0,
        date: moment.utc()
      };
    },

    created() {
      this.loadData();
    },

    methods: {
      setDate(date) {
        this.date = date;
      },

      setShop(shop) {
        this.shop = shop;
        this.tags = shop ? shop.tagList : '';
        this.name = 'Zbiorczy';
        this.total = 0;
      },

      loadData() {
        this.loading = true;

        const hideLoader = () => (this.loading = false);

        this.updateShopsList()
          .then(hideLoader, hideLoader);
      },

      validate() {
        if (!this.shop || !this.total || !this.date || !this.name) {
          return false;
        }

        return Number(this.total) >= MIN_COST;
      },

      checkBeforeSubmit() {
        if (!this.validate()) {
          this.$refs.button.shake();

          return;
        }

        return this.submitReceipt();
      },

      async submitReceipt() {
        this.saving = true;

        const receipts = await this.$api.getReceipts({
          total: this.total,
          shopId: this.shop.id,
          date: this.date.startOf('day').toISOString()
        });

        if (receipts.meta.total > 0) {
          const confirmation = await this.showModal(Modals.RECEIPT_ADD_CONFIRM, {
            shop: this.shop,
            date: this.date,
            total: this.total
          })

          if (!confirmation) {
            this.saving = false;

            if (this.$refs.shop) {
              this.$refs.shop.focus();
            }

            return;
          }
        }

        try {
          const receipt = await this.$api.saveReceipt({
            shopId: this.shop.id,
            date: this.date.toISOString(),
            name: this.name,
            tags: this.tags,
            total: this.total
          });

          this.showSuccessMessage(`Dodano paragon w ${this.shop.name} na ${receipt.total} zł.`, `/receipts/${receipt.id}`);
        } catch (error) {
          this.showFailMessage(error.message);
        }

        if (this.$refs.shop) {
          this.$refs.shop.focus();
        }

        this.saving = false;
      },

      showReceiptDetails(event) {
        if (event.shiftKey) {
          return;
        }

        this.$router.push({
          path: '/receipts/new',
          query: {
            shopId: (this.shop && this.shop.id) || void 0,
            date: this.date.format('YYYY-MM-DD'),
            name: this.name,
            total: this.total,
            tags: this.tags || void 0
          }
        });
        event.preventDefault();
      },

      createShop(name, event) {
        event.preventDefault();

        this.showModal(Modals.SHOP_FORM, {
          name
        })
          .then((shop) => {
            if (shop) {
              if (this.shopsList) {
                this.shopsList.push(shop);
              }

              this.setShop(shop);

              if (this.$refs.date) {
                this.$refs.date.focus();
              }
            } else {
              this.$refs.shop.focus();
            }
          });
      }
    }
  };
</script>
