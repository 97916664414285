<template>
  <Page :loading="loading">
    <h1 slot="header">Odbiorcy</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
      <button type="button" @click="addRecipient">Nowy odbiorca</button>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/bills">Rachunki</RelatedItem>
      </Related>

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="name" as-default="asc">Nazwa</Sorter>
        <Sorter by="created">Data utworzenia</Sorter>
        <Sorter by="address">Adres</Sorter>
        <Sorter by="cost">Kwota</Sorter>
        <Sorter by="title">Tytuł</Sorter>
        <Sorter by="description">Opis</Sorter>
      </div>
    </template>

    <template slot="body" v-if="loadingItems">
      <Loader/>
    </template>

    <template slot="body" v-else>
      <List :items="items" :meta="meta">
        <template slot="actions" slot-scope="{ selected }">
          <BulkActionItem @click.native="markActive(selected)">Aktywuj</BulkActionItem>
          <BulkActionItem @click.native="markInactive(selected)">Deaktywuj</BulkActionItem>
        </template>
        <RecipientItem slot-scope="{ item }" :recipient="item" @remove="load"/>
      </List>
    </template>
  </Page>
</template>

<script>
  import Sorter from '@components/Sorter/Sorter';
  import List from '@components/List/List';
  import Loader from '@components/Loader';
  import Page from '@components/Page';

  import { ListMixin } from '@mixins';
  import BulkActionItem from '@components/List/BulkActionItem';
  import RecipientsMixin from '@components/Bills/RecipientsMixin';
  import RecipientItem from '@components/Bills/RecipientItem';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';

  export default {
    inject: [
      '$api',
      'showModal',
    ],

    mixins: [
      RecipientsMixin,
      ListMixin
    ],

    components: {
      RelatedItem,
      Related,
      BulkActionItem,
      RecipientItem,
      Loader,
      Sorter,
      List,
      Page
    },

    methods: {

      toggleRecipient(recipient, newActive) {
        return this.$api.saveRecipient({
          id: recipient.id,
          active: newActive
        })
          .then((newRecipient) => {
            recipient.active = newRecipient.active;
            this.recipientActiveUpdated(newRecipient);
          }, (err) => this.recipientError(err));
      },

      markActive(selected) {
        selected.forEach((recipient) => {
          if (!recipient.active) {
            this.toggleRecipient(recipient, true);
          }
        });
      },

      markInactive(selected) {
        selected.forEach((recipient) => {
          if (recipient.active) {
            this.toggleRecipient(recipient, false);
          }
        });
      },

      addRecipient() {
        this.showModal(Modals.RECIPIENT_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano odbiorcę ${result.name}`);
              this.load();
            }
          })
      },

      fetch(query) {
        if (!query.order) {
          query.order = 'name asc';
        }

        return this.$api.getRecipients(query);
      }
    }
  };
</script>
