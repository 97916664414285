import { parsePeriodQuery, toPeriod } from '@utils/periods';

import MessageMixin from './MessageMixin';
import QueryMixin from './QueryMixin';

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 50;

export default {
  mixins: [
    MessageMixin,
    QueryMixin
  ],

  data() {
    return {
      query: this.$route.query.q,
      page: this.$route.query.page || DEFAULT_PAGE,
      limit: this.$route.query.limit || DEFAULT_LIMIT,
      order: this.$route.query.order,
      meta: null,
      items: [],
      summary: [],
      loadingItems: false,
      loadingSummary: false,
      loading: false,
      ...parsePeriodQuery(this.$route.query)
    };
  },

  computed: {
    q: {
      get() {
        return this.query;
      },

      set(val) {
        this.$router.push(this.toQueryLink({
          q: val || void 0
        }));
      }
    },

    period() {
      return toPeriod(this);
    }
  },

  watch: {
    $route(to) {
      const { year, month, date } = parsePeriodQuery(to.query);

      if (year !== this.year) {
        this.year = year;
      }

      if (month !== this.month) {
        this.month = month;
      }

      if (date !== this.date) {
        this.date = date;
      }

      if (to.query.page !== this.page) {
        this.page = Math.max(Number(to.query.page), 1) || null;
      }

      if (to.query.order !== this.order) {
        this.order = to.query.order;
      }

      if (to.query.q !== this.query) {
        this.query = to.query.q;
      }

      if (to.query.limit !== this.limit) {
        this.limit = Math.max(Number(to.query.limit), 1) || DEFAULT_LIMIT;
      }
    },

    date() {
      this.load();
    },

    year() {
      this.load();
    },

    month() {
      this.load();
    },

    query() {
      this.loadAll();
    },

    order() {
      this.load();
    },

    page() {
      this.load();
    }
  },

  methods: {
    toQueryLink(query) {
      return {
        query: {
          ...this.$route.query,
          ...query
        }
      };
    },

    fetch() {
      return Promise.resolve({
        items: [],
        meta: null
      });
    },

    fetchSummary() {
      return Promise.resolve([]);
    },

    loadAll() {
      return Promise.all([
        this.load(),
        this.loadSummary()
      ]);
    },

    load() {
      this.loadingItems = true;

      return this.fetch({
        q: this.query,
        limit: this.limit,
        order: this.order,
        period: this.period,
        offset: this.page ? (this.page - 1) * this.limit : 0
      })
        .then(({ meta, items }) => {
          this.meta = meta;
          this.items = items;
          this.loadingItems = false;

          return items;
        }, (err) => {
          this.showFailMessage(err.message);
          this.loadingItems = false;
        });
    },

    loadSummary() {
      this.loadingSummary = true;

      return this.fetchSummary({
        q: this.query,
        limit: this.limit,
        order: this.order,
        period: this.period
      })
        .then((summary) => {
          this.summary = summary;
          this.loadingSummary = false;

          return summary;
        }, (err) => {
          this.showFailMessage(err.message);
          this.loadingSummary = false;
        });
    }
  },

  created() {
    this.loading = true;

    this.loadAll()
      .then(() => (this.loading = false));
  }
};
