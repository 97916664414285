<template>
  <input type="number" v-model.lazy="rawAmount" :placeholder="placeholder" step="0.01" v-auto-select/>
</template>

<script>
import toNumber from '@utils/toNumber';

export default {
  props: {
    placeholder: {
      type: String,
      default: 'np. 0.00'
    },
    amount: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      rawAmount: this.amount
    };
  },

  watch: {
    rawAmount(value) {
      this.$emit('update:amount', toNumber(value));
    },

    amount(value) {
      this.rawAmount = value;
    }
  }
}
</script>
