import moment from 'moment';

export function createGroupingFunction(config) {
  const {
    getGroup,
    getLink,
    getLabel
  } = config;

  return function group(items) {
    const map = {};
    const itemGroupMap = new Map();

    items.forEach((item) => {
      const groupId = getGroup(item);

      if (!map[groupId]) {
        map[groupId] = {
          id: groupId,
          label: getLabel?.(groupId) || groupId,
          link: getLink?.(groupId) || null,
          items: []
        }
      }

      map[groupId].items.push(item);
      itemGroupMap.set(item, map[groupId]);
    });

    return {
      groups: Object.values(map),
      itemGroupMap
    };
  }
}

export function getDateGroupLabel(date) {
  return moment.utc(date).format('MMMM YYYY')
}
