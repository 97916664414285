<template>
  <SubmitModal ref="modal" :submit="saveIncome">
    <template slot="cancel">Anuluj</template>
    <template slot="header" slot-scope="{ item }">{{ item.id ? 'Edytuj wpływ' : 'Nowy wpływ' }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.id ? 'Zapisz zmiany' : 'Dodaj wpływ' }}</template>
    <IncomeForm slot="body" slot-scope="{ item }" :income="item" />
  </SubmitModal>
</template>

<script>
  import IncomeForm from '@components/Income/IncomeForm';
  import SubmitModal from '@components/Modal/SubmitModal';

  export default {
    inject: [
      '$api',
      'showSuccessMessage',
      'showFailMessage'
    ],

    components: {
      SubmitModal,
      IncomeForm,
    },

    methods: {
      async show(item) {
        const income = Object.assign({
          id: null,
          payerId: null,
          name: '',
          date: new Date(),
          description: '',
          income: null
        }, item);


        if (!income.id && income.payerId) {
          const payer = await this.$api.getPayerListItem(income.payerId);

          if (payer) {
            income.name = payer.name;
            income.description = payer.defaultDescription;
            income.income = payer.defaultIncome;
          }
        }

        return this.$refs.modal.show(income);
      },

      saveIncome(income) {
        return this.$api.saveIncome(income);
      }
    }
  };
</script>
