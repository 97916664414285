<template>
  <SubmitModal ref="modal" :submit="remove">
    <template slot="header">Uwaga!</template>
    <template slot="cancel">Nie</template>
    <template slot="submit">Tak, usuń</template>
    <div slot="body" slot-scope="{ item }">
      <p>Czy na pewno chcesz usunąć ten sklep? Niewiadomo co się stanie z paragonami :)</p>

      <div class="expense-wrapper">
        <ShopSummary :shop="item"/>
      </div>
    </div>
  </SubmitModal>
</template>

<script>
  import SubmitModal from '@components/Modal/SubmitModal';
  import ShopSummary from '@components/Shops/ShopSummary';

  export default {
    inject: [
      '$api'
    ],

    components: {
      ShopSummary,
      SubmitModal
    },

    methods: {
      show(payer) {
        return this.$refs.modal.show(payer);
      },

      remove(shop) {
        return this.$api.deleteShop(shop.id);
      }
    }
  };
</script>
