<template>
  <li class="summary-item" :title="hint">
    <div class="total" :style="style">&nbsp;</div>
    <router-link :to="link">{{ item.label }}</router-link>
    <span class="count">{{ item.total | currency }}</span>
  </li>
</template>

<script>
  export default {
    props: {
      item: {
        default: () => ({
          label: '',
          total: 0,
          totalShare: 0,
          totalVsMax: 0,
          count: 0,
          countShare: 0,
          countVsMax: 0,
          period: ''
        })
      },

      formatHint: {
        default() {
          return (count) => `Pozycji: ${count}.`;
        }
      }
    },

    computed: {
      link() {
        return {
          query: Object.assign({}, this.$route.query, {
            period: this.item.period,
            page: void 0
          })
        };
      },

      hint() {
        return this.formatHint(this.item.count);
      },

      style() {
        return `width: ${this.item.totalVsMax * 100}%`;
      }
    }
  };
</script>
