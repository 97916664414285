<template>
  <SubmitModal ref="modal" :submit="savePayer">
    <template slot="cancel">Anuluj</template>
    <template slot="header" slot-scope="{ item }">{{ item.id ? 'Edytuj dawcę' : 'Nowy dawca' }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.id ? 'Zapisz zmiany' : 'Dodaj dawcę' }}</template>
    <PayerForm slot="body" slot-scope="{ item }" :payer="item"/>
  </SubmitModal>
</template>

<script>
  import PayerForm from '@components/Income/Payers/PayerForm';
  import SubmitModal from '@components/Modal/SubmitModal';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
      PayerForm
    },

    methods: {
      show(payer) {
        return this.$refs.modal.show(Object.assign({
          id: null,
          name: '',
          defaultDescription: '',
          defaultIncome: null
        }, payer));
      },

      savePayer(payer) {
        return this.$api.savePayer(payer);
      }
    }
  };
</script>
