import { createGroupingFunction, getDateGroupLabel } from '@utils/grouping';

export function createIncomeGroupingFunction(order) {
  if (!order || order.startsWith('date')) {
    return createGroupingFunction({
      getGroup: (income) => income.date.substring(0, 7),
      getLabel: getDateGroupLabel,
      getLink: (date) => `/income?period=${date}`
    });
  }

  if (!order || order.startsWith('created')) {
    return createGroupingFunction({
      getGroup: (income) => income.created.substring(0, 7),
      getLabel: getDateGroupLabel
    });
  }

  if (!order || order.startsWith('name')) {
    return createGroupingFunction({
      getGroup: (income) => income.name
    });
  }

  return null;
}
