import { createGroupingFunction, getDateGroupLabel } from '@utils/grouping';
import { getShopLabel } from '@utils/shops';

export function createProductGroupingFunction(order) {
  if (!order || order.startsWith('receipt date')) {
    return createGroupingFunction({
      getGroup: (product) => product.receipt.date.substring(0, 7),
      getLabel: getDateGroupLabel
    });
  }

  if (order.startsWith('created')) {
    return createGroupingFunction({
      getGroup: (product) => product.created.substring(0, 7),
      getLabel: getDateGroupLabel
    });
  }

  if (order.startsWith('receipt shop name')) {
    return createGroupingFunction({
      getGroup: (product) => getShopLabel(product.receipt.shop)
    });
  }

  return null;
}
