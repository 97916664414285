<template>
  <SubmitModal ref="modal" :submit="remove">
    <template slot="header">Uwaga!</template>
    <template slot="cancel">Nie</template>
    <template slot="submit">Tak, usuń</template>
    <div slot="body" slot-scope="{ item }">
      <p>Czy na pewno chcesz usunąć tego dawcę?</p>

      <div class="expense-wrapper">
        <PayerSummary :payer="item"/>
      </div>
    </div>
  </SubmitModal>
</template>

<script>
  import PayerSummary from '@components/Income/Payers/PayerSummary';
  import SubmitModal from '@components/Modal/SubmitModal';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
      PayerSummary
    },

    methods: {
      show(payer) {
        return this.$refs.modal.show(payer);
      },

      remove(payer) {
        return this.$api.deletePayer(payer.id);
      }
    }
  };
</script>
