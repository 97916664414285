<template>
  <receipt title="Nowy paragon"
           save-button="Dodaj paragon"
           :receipt="receipt"
           @receipt-saved="onSave"
           @receipt-not-saved="onError"
  />
</template>

<script>
  import Receipt from '../Receipt/Receipt';
  import MessageMixin from '../../mixins/MessageMixin';

  export default {
    name: 'new-receipt',

    mixins: [
      MessageMixin
    ],

    components: {
      Receipt
    },

    data() {
      const { query } = this.$route;
      const receipt = {
        shopId: Number(query.shopId),
        date: query.date,
        products: [
          {
            name: query.name || 'Zbiorczy',
            cost: Number(query.total) || 0,
            quantity: 1,
            tagList: query.tags || ''
          }
        ]
      };

      return {
        receipt
      };
    },

    methods: {
      onSave(receipt) {
        this.showSuccessMessage('Dodano paragon');
        this.$router.push({
          path: `/receipts/${receipt.id}`
        });
      },

      onError(err) {
        this.showFailMessage(err.message);
      }
    }
  };
</script>

