<template>
  <page class="receipt"
        :loading="!ready || loading"
        @submit="checkBeforeSubmit()">
    <template slot="actions">
      <button type="button" @click.prevent="addProduct">
        <i class="fa fa-plus"></i>Dodaj produkt
      </button>
      <button type="button"
              v-if="receipt && receipt.id"
              @click="confirmReceiptDelete(receipt)">
        <i class="fa fa-trash-o"></i>Usuń
      </button>
    </template>

    <h1 slot="header">{{ title }}</h1>

    <template slot="aside">
      <shop-input ref="shop"
                  :class="{ error: errors.shop }"
                  :autoFocus="!shop"
                  :shop="shop"
                  :shops="shopsList"
                  @select="setShop"
                  @not-found="createShop"/>
      <date-picker ref="date" :start-date="date" @select="setDate"/>
      <h3 class="total">Razem: {{ total }} zł</h3>
      <spinning-button ref="button"
                       class="save-button"
                       :class="{ dirty: dirty }"
                       :loading="saving">
        {{ saveButton }}
      </spinning-button>
    </template>

    <template slot="body">
      <div class="columns">
        <p class="product-name">Nazwa</p>
        <p class="product-cost">Cena</p>
        <p class="product-quantity">Ilość</p>
      </div>
      <product-item
        ref="products"
        v-for="(product, index) in products"
        :key="product.id"
        :autoFocus="shop && products.length === 1"
        :list="productsList"
        :product="product"
        :show-errors="true"
        @next="addProduct($event, index)"
        @remove="removeProduct(index)"
      />

      <confirm-receipt-delete v-if="receiptToDelete"
                              :receipt="receiptToDelete"
                              :loading="deleting"
                              @confirm="deleteReceipt"
                              @close="receiptToDelete = null"
      />
    </template>
  </page>
</template>

<script>
  import ShopInput from '../ShopInput/ShopInput';
  import DatePicker from '../DatePicker/DatePicker';
  import ProductItem from '../ProductItem/ProductItem';
  import SpinningButton from '../SpinningButton/SpinningButton';
  import Page from '../Page';
  import moment from 'moment';
  import ShopListMixin from '../../mixins/ShopListMixin';
  import ProductListMixin from '../../mixins/ProductListMixin';
  import DeleteReceiptMixin from '../../mixins/DeleteReceiptMixin';
  import { Modals } from '@components/consts';

  export default {
    name: 'receipt',

    inject: [
      '$api',
      'showModal'
    ],

    mixins: [
      ShopListMixin,
      ProductListMixin,
      DeleteReceiptMixin
    ],

    components: {
      ShopInput,
      DatePicker,
      ProductItem,
      SpinningButton,
      Page
    },

    props: {
      ready: {
        default: true
      },
      title: {
        default: 'Paragon'
      },
      saveButton: {
        default: 'Zapisz'
      },
      receipt: {
        default: null
      }
    },

    data() {
      const data = {
        loading: false,
        saving: false,
        dirty: false,

        shopsList: [],
        productsList: [],

        id: null,
        shopId: null,
        date: moment.utc(),
        products: [],
        defaultTagList: ''
      };

      if (this.receipt) {
        data.id = this.receipt.id;
        data.shopId = this.receipt.shop ? this.receipt.shop.id : this.receipt.shopId;
        data.date = moment.utc(this.receipt.date).startOf('day');
        data.products = Array.isArray(this.receipt.products) ? this.receipt.products : [];
        data.defaultTagList = (this.receipt.shop && this.receipt.shop.tagList) || '';
      }

      if (!data.products.length) {
        data.products.push({
          name: 'Zbiorczy',
          cost: 0,
          quantity: 1,
          tagList: data.defaultTagList
        });
      }

      return data;
    },

    computed: {
      total: {
        get() {
          return this.products
            .reduce((sum, item) => sum + (item.quantity * item.cost), 0)
            .toFixed(2);
        }
      },

      errors() {
        return {
          shop: !this.shop || !this.shop.id,
          date: !this.date
        };
      }
    },

    watch: {
      products: {
        handler(newVal, oldVal) {
          if (newVal === oldVal) {
            this.dirty = true;
          }
        },
        deep: true
      },

      receipt(val) {
        this.id = val.id;
        this.date = moment.utc(val.date).startOf('day');

        if (val.shopId && !val.shop) {
          this.shopId = val.shopId;
        } else {
          this.shopId = val.shop.id;
          this.defaultTagList = val.shop.tagList;
        }

        this.products = Array.isArray(val.products) ? val.products : [];
        this.dirty = false;
      }
    },

    created() {
      this.loadData();
    },

    methods: {
      setDate(date) {
        this.date = date;
        this.errors.date = !date;
        this.dirty = true;
      },

      setShop(shop) {
        this.shop = shop;
        this.defaultTagList = shop ? shop.tagList : '';
        this.errors.shop = !shop;
        this.dirty = true;
      },

      loadData() {
        this.loading = true;

        const hideLoader = () => (this.loading = false);

        Promise.all([
          this.updateShopsList(),
          this.updateProductsList()
        ])
          .then(hideLoader, hideLoader);
      },

      checkBeforeSubmit() {
        const hasMetaData = this.shop && Number(this.total) && this.date;
        const hasProductsAllData = this.products
          .every((product) => product.name.length > 0 && product.cost && product.quantity);

        if (!hasMetaData || !hasProductsAllData) {
          this.$refs.button.shake();

          return;
        }

        this.submitReceipt();
      },

      submitReceipt() {
        this.saving = true;

        return this.$api.saveReceipt({
          id: this.id,
          shopId: this.shop.id,
          date: this.date.toISOString(),
          products: this.products,
          total: this.total
        })
          .then((receipt) => {
            this.saving = false;
            this.dirty = false;
            this.$emit('receipt-saved', receipt);
          }, (error) => {
            this.saving = false;
            this.$emit('receipt-not-saved', error);
          });
      },

      addProduct(event, index = this.products.length - 1) {
        if (index + 1 < this.products.length) {
          return;
        }

        this.products.push({
          name: '',
          quantity: 1,
          cost: 0,
          tagList: this.defaultTagList
        });

        this.dirty = true;

        event.preventDefault();
        this.$nextTick(() => this.$refs.products[index + 1].focus());
      },

      removeProduct(index) {
        this.products.splice(index, 1);
        this.dirty = true;
      },

      createShop(name, event) {
        event.preventDefault();

        this.showModal(Modals.SHOP_FORM, {
          name
        })
          .then((shop) => {
            if (shop) {
              if (this.shopsList) {
                this.shopsList.push(shop);
              }

              this.setShop(shop);

              if (this.$refs.date) {
                this.$refs.date.focus();
              }
            } else {
              this.$refs.shop.focus();
            }
          })
      }
    }
  };

</script>

<style lang="scss" src="./Receipt.scss"></style>
