<template>
  <div class="loader">
    <i class="fa fa-spin fa-spinner"/>Ładowanie...
  </div>
</template>

<script>
  export default {
    name: 'loader'
  };
</script>

<style lang="scss">
  .loader {
    position: absolute;
    width: 50%;
    height: 20%;
    left: 25%;
    top: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5em;

    i {
      margin-right: 0.5em;
    }
  }
</style>
