<template>
  <Page :loading="loading">
    <h1 slot="header">Wpływy</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
      <button type="button" @click="addIncome">Nowy wpływ</button>
      <button type="button" @click="addPayer">Nowy dawca</button>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/income/payers/">Dawcy</RelatedItem>
      </Related>

      <SummaryFilter
        :summary-data="summary"
        :period="period"
        :month="month"
        :year="year"
        :date="date"
      />

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="date" as-default="desc">Data wpłynięcia</Sorter>
        <Sorter by="created">Data utworzenia</Sorter>
        <Sorter by="income">Kwota</Sorter>
        <Sorter by="name">Dawca</Sorter>
      </div>
    </template>

    <template slot="body" v-if="loadingItems">
      <Loader/>
    </template>

    <template slot="body" v-else>
      <List :items="items" :meta="meta" :groups="groups">
        <template slot="group-header" slot-scope="{ item, group }">
          <h4 class="group-by-date">
            <template v-if="group.link">
              <router-link :to="group.link">{{ group.label }}</router-link>
            </template>
            <template v-else>
              {{ group.label }}
            </template>
          </h4>
        </template>
        <IncomeItem slot-scope="{ item }" :income="item" @remove="load()"/>
      </List>
    </template>
  </Page>
</template>

<script>
  import List from '@components/List/List';
  import Loader from '@components/Loader';
  import { ListMixin } from '@mixins';
  import Page from '@components/Page';
  import SummaryFilter from '@components/Summary/SummaryFilter';
  import Sorter from '@components/Sorter/Sorter';
  import { createIncomeGroupingFunction } from './grouping';
  import IncomeItem from './IncomeItem';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    mixins: [
      ListMixin
    ],

    components: {
      RelatedItem,
      Related,
      IncomeItem,
      SummaryFilter,
      Sorter,
      Page,
      List,
      Loader
    },

    computed: {
      groups() {
        const handler = createIncomeGroupingFunction(this.order);

        if (!handler) {
          return this.items.map(() => null);
        }

        const { itemGroupMap } = handler(this.items)

        return this.items.map((item) => itemGroupMap.get(item));
      }
    },

    methods: {
      addPayer() {
        this.showModal(Modals.PAYER_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano dawcę ${result.name}`);
            }
          })
      },

      addIncome() {
        this.showModal(Modals.INCOME_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano wpływ od ${result.name}`);
              this.load();
            }
          })
      },

      fetch(query) {
        if (!query.order) {
          query.order = 'date desc';
        }

        return this.$api.getIncomes(query);
      },

      fetchSummary(query) {
        return this.$api.getIncomesSummary(query);
      }
    }
  };
</script>
