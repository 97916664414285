<template>
  <Page :loading="loading">
    <h1 slot="header">Tagi</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
      <button type="button" @click="addTag">Nowy tag</button>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/receipts">Paragony</RelatedItem>
        <RelatedItem to="/products">Produkty</RelatedItem>
        <RelatedItem to="/shops">Sklepy</RelatedItem>
      </Related>

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="name" as-default="asc">Nazwa</Sorter>
        <Sorter by="created">Data utworzenia</Sorter>
        <Sorter by="!total">Kwota</Sorter>
        <Sorter by="!count">Produktów</Sorter>
      </div>
    </template>

    <template slot="body" v-if="loadingItems">
      <Loader/>
    </template>

    <template slot="body" v-else>
      <List :items="items" :meta="meta">
        <TagItem slot-scope="{ item }" :tag="item" @remove="load()"/>
      </List>
    </template>
  </Page>
</template>

<script>
  import { ListMixin } from '@mixins';
  import List from '@components/List/List';
  import Loader from '@components/Loader';
  import Page from '@components/Page';
  import Sorter from '@components/Sorter/Sorter';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';
  import TagItem from '@components/Tags/TagItem';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    mixins: [
      ListMixin
    ],

    components: {
      TagItem,
      RelatedItem,
      Related,
      Sorter,
      Page,
      List,
      Loader
    },

    methods: {
      addTag() {
        // this.showModal(Modals.PAYER_FORM, {})
        //   .then((result) => {
        //     if (result) {
        //       this.showSuccessMessage(`Dodano dawcę ${result.name}`);
        //       this.load();
        //     }
        //   })
      },

      fetch(query) {
        if (!query.order) {
          query.order = 'name asc';
        }

        return this.$api.getTags(query);
      }
    }
  };
</script>
