<template>
  <div class="list-item">
    <div class="list-item-header">
      <router-link class="list-item-summary list-item-interactive" :to="to" v-if="to">
        <slot name="summary" />
      </router-link>

      <div class="list-item-summary" v-else>
        <slot name="summary" />
      </div>
      <slot name="actions" />
    </div>

    <slot name="modals"/>
  </div>
</template>

<script>
  export default {
    props: ['to']
  };
</script>
