<template>
  <ItemSummary
    :name="shop.name"
    :description="address"
    :amount="shop.receiptTotal"
    :quantity="`Paragonów ${shop.receiptCount}`"
    :tags="shop.tags"
  />
</template>

<script>
  import ItemSummary from '@components/List/ItemSummary';
  export default {
    components: { ItemSummary },
    props: ['shop'],

    computed: {
      address() {
        if (!this.shop) {
          return '';
        }

        const shop = this.shop;

        if (shop.address && shop.city) {
          return `${shop.address}, ${shop.city}`;
        }

        return shop.address || shop.city;
      }
    }
  };
</script>
