<template>
  <div class="list">
    <Pagination :meta="meta"/>

    <div class="items-list">
      <div class="items-list-bulk-actions">
        <a class="items-list-bulk-action" @click="toggleAll">{{ toggleAllLabel }}</a>
        <slot name="actions" :selected="selectedList"/>
      </div>

      <template v-for="(item, index) in items" >
        <slot v-if="isFirstInGroup(item)" name="group-header" :item="item" :group="itemGroups[index]"/>
        <div class="list-item-wrapper" :key="item.id">
          <ListItemSelect :selected="selected[item.id]" @click.native="selected[item.id] = !selected[item.id]"/>
          <slot :item="item" :group="itemGroups[index]"/>
        </div>
      </template>
    </div>

    <Pagination :meta="meta"/>
  </div>
</template>

<script>
  import Pagination from '@components/Pagination/Pagination';
  import ListItemSelect from './ListItemSelect';

  import { map } from 'lodash';

  function buildSelected(items) {
    return items.reduce((agg, item) => {
      agg[item.id] = false;

      return agg;
    }, {});
  }

  export default {
    components: {
      ListItemSelect,
      Pagination
    },

    props: {
      meta: {
        type: Object
      },
      items: {
        type: Array
      },
      actions: {
        type: Array
      },
      groups: {
        default: null
      },
      groupBy: {
        default: null
      }
    },

    data() {
      return {
        selected: buildSelected(this.items)
      };
    },

    watch: {
      items() {
        this.selected = buildSelected(this.items);
      }
    },

    computed: {
      itemGroups() {
        return this.groups || map(this.items, this.groupBy || (() => null));
      },

      selectedList() {
        return this.items.filter((item) => this.selected[item.id]);
      },

      areAllSelected() {
        return this.items.every((item) => this.selected[item.id]);
      },

      toggleAllLabel() {
        return this.areAllSelected ? 'Odznacz' : 'Zaznacz';
      }
    },

    methods: {
      toggleAll() {
        const newValue = !this.areAllSelected;

        this.items.forEach((item) => (this.selected[item.id] = newValue));
      },

      runAction(action) {
        this.$emit(action.name, this.selectedList);
      },

      isFirstInGroup(item) {
        const index = this.items.indexOf(item);
        const groupName = this.itemGroups[index];

        if (!groupName) {
          return false;
        }

        return this.itemGroups.findIndex((group) => group === groupName) === index;
      }
    }
  };
</script>
