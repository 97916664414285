<template>
  <modal @close="$emit('close')">
    <template slot="header">Uwaga!</template>

    <div slot="body">
      <p>Czy na pewno chcesz usunąć ten paragon?</p>

      <div class="expense-wrapper">
        <ReceiptSummary :receipt="receipt"/>
      </div>
    </div>

    <div class="footer" slot="footer">
      <button ref="cancel" class="cancel" @click="$emit('close')">Nie</button>
      <spinning-button ref="confirm" @click.native="$emit('confirm', receipt)" :loading="loading" class="primary" v-focus>Tak, usuń</spinning-button>
    </div>
  </modal>
</template>

<script>
  import Modal from './Modal';
  import SpinningButton from '../SpinningButton/SpinningButton';
  import ReceiptSummary from '@components/ReceiptsList/ReceiptSummary';

  export default {
    name: 'confirm-receipt-delete',

    components: {
      ReceiptSummary,
      Modal,
      SpinningButton
    },

    props: ['receipt', 'loading']
  };
</script>
