import ConfirmReceiptDelete from '../components/Modal/ConfirmReceiptDelete';

export default {
  inject: [
    '$api'
  ],

  components: {
    ConfirmReceiptDelete
  },

  data() {
    return {
      deleting: false,
      receiptToDelete: null
    };
  },

  methods: {
    confirmReceiptDelete(receipt) {
      this.receiptToDelete = receipt;
    },

    deleteReceipt(receipt) {
      this.deleting = true;
      this.$api.deleteReceipt(receipt.id)
        .then((deletedReceipt) => this.$emit('receipt-deleted', deletedReceipt))
        .catch((err) => this.$emit('receipt-failed-to-delete', err, receipt))
        .then(() => {
          this.deleting = false;
          this.receiptToDelete = null;
        });
    }
  }
};
