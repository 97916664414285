import Vue from 'vue';
import VueRouter from 'vue-router';
import api from '../api';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => api.authPromise
  .then(
    function whenLoggedIn() {
      if (to.path !== '/') {
        return next();
      }

      const redirectQuery = to.query.redirect;
      const target = (redirectQuery && decodeURIComponent(redirectQuery)) || '/dashboard';

      return next(target);
    },
    function whenNotLoggedIn() {
      next(to.path === '/' ? void 0 : `/?redirect=${encodeURIComponent(to.fullPath)}`);
    }
  ));

router.afterEach((to) => {
  document.title = to.meta.title ? `${to.meta.title} - Wydatki` : 'Wydatki';
});

export default router;
