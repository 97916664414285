<template>
  <div class="date-picker" :class="{ active: active }"  @keydown="handleFocus($event)">
    <input ref="input"
           class="date-picker-input"
           type="text"
           v-model="dateString"
           :placeholder="placeholder"
           @focus="activate()"
           @click="activate()"
           @blur="deactivate()"
    >
    <div class="date-picker-calendar" v-show="active">
      <table class="calendar-grid">
        <tr class="month-control">
            <td @click="goToPrevMonth()" @mousedown.prevent>&#10218;</td>
            <th colspan="4">{{ date.format('MMMM YYYY') }}</th>
            <td @click="goToToday()" @mousedown.prevent>&#9872;</td>
            <td @click="goToNextMonth()" @mousedown.prevent>&#10219;</td>
        </tr>
        <tr class="week-names">
          <td v-for="day in weekdaysShort">{{ day }}</td>
        </tr>
        <tr class="days-grid" v-for="week in calendarGrid">
          <td
            v-for="day in week"
            :class="{
              'other-month': day.month() !== month,
              current: date.isSame(day, 'day')
            }"
            @click.prevent="select(day, true)"
            @mousedown.prevent
          >{{ day.date() }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style lang="scss">
  @import "DatePicker.scss";
</style>

<script>
import moment from 'moment';

moment.locale('pl');

const KeyCodes = {
  DOWN: 40,
  RIGHT: 39,
  UP: 38,
  LEFT: 37,
  ENTER: 13,
  ESC: 27,
  TAB: 9
};

const DAYS_IN_WEEK = 7;

export default {
  name: 'date-picker',
  props: {
    placeholder: {
      type: String,
      default: () => ''
    },
    startDate: {
      type: moment,
      default: () => moment.utc().startOf('day')
    },
    dateFormat: {
      type: String,
      default: () => 'YYYY-MM-DD'
    }
  },

  watch: {
    startDate(val) {
      this.date = val;
    }
  },

  data() {
    return {
      active: false,
      date: this.startDate
    };
  },

  computed: {
    dateString: {
      set(val) {
        const newDate = moment.utc(val, this.dateFormat).startOf('day');

        if (val.length === this.dateFormat.length && newDate.isValid()) {
          this.select(newDate);
        }
      },

      get() {
        return this.date.format(this.dateFormat);
      }
    },

    weekdaysShort() {
      return moment.weekdaysShort(true);
    },

    month() {
      return this.date.month();
    },

    calendarGrid() {
      const start = moment.utc(this.date).startOf('month').startOf('week');
      const end = moment.utc(this.date).endOf('month').endOf('week');
      const weeks = end.diff(start, 'weeks') + 1;

      return Array
        .from(new Array(weeks))
        .map((ignoreA, week) => Array
          .from(new Array(DAYS_IN_WEEK))
          .map((ignoreB, day) => moment.utc(start).add((week * DAYS_IN_WEEK) + day, 'days'))
        );
    }
  },

  mounted() {
    this.$input = this.$el.querySelector('input');
  },

  methods: {
    activate() {
      this.active = true;
      const dateLength = this.dateString.length;

      this.$el.querySelector('input').setSelectionRange(dateLength - 2, dateLength);
    },

    deactivate() {
      this.active = false;
    },

    goToToday() {
      this.select(moment.utc());
    },

    goToNextMonth() {
      this.select(this.date.clone().add(1, 'month'));
    },

    goToPrevMonth() {
      this.select(this.date.clone().subtract(1, 'month'));
    },

    select(date, shouldDeactivate = false) {
      if (!date.isValid()) {
        return;
      }

      this.date = date;
      this.$emit('select', date);

      if (shouldDeactivate) {
        this.deactivate();
      }
    },

    handleGridFocus(keyCode) {
      if (!this.active) {
        this.active = true;

        return;
      }

      switch (keyCode) {
        case KeyCodes.LEFT:
          this.select(moment.utc(this.date).subtract(1, 'days'));
          break;
        case KeyCodes.UP:
          this.select(moment.utc(this.date).subtract(DAYS_IN_WEEK, 'days'));
          break;
        case KeyCodes.RIGHT:
          this.select(moment.utc(this.date).add(1, 'days'));
          break;
        case KeyCodes.DOWN:
          this.select(moment.utc(this.date).add(DAYS_IN_WEEK, 'days'));
          break;
        default:
          break;
      }
    },

    handleFocus(event) {
      let handled = true;

      switch (event.keyCode) {
        case KeyCodes.DOWN:
        case KeyCodes.RIGHT:
        case KeyCodes.UP:
        case KeyCodes.LEFT:
          this.handleGridFocus(event.keyCode);
          break;
        case KeyCodes.ENTER:
          this.select(moment.utc(this.$input.value, this.dateFormat), true);
          break;
        case KeyCodes.TAB:
          this.select(moment.utc(this.$input.value, this.dateFormat));
          handled = false;
          break;
        case KeyCodes.ESC:
          if (this.active) {
            this.deactivate();
          } else {
            handled = false;
          }
          break;
        default:
          handled = false;
          break;
      }

      if (handled) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>
