export default {
  methods: {
    toQueryLink(query) {
      return {
        query: {
          ...this.$route.query,
          ...query
        }
      };
    }
  }
};
