<template>
  <div class="recipient-form">
    <section>
      <h4>Dane podstawowe</h4>
      <label>
        <span>Nazwa</span>
        <input type="text" v-model="recipient.name" placeholder="np. Inea" v-auto-select/>
      </label>
      <label>
        <span>Kwota</span>
        <AmountInput :amount.sync="recipient.cost"/>
      </label>
      <label>
        <span>Termin</span>
        <date-picker :start-date="deadline" @select="setDate"/>
      </label>
      <label>
        <span>Opis</span>
        <textarea v-model="recipient.description" placeholder="np. rachunek za gaz" v-auto-select></textarea>
      </label>
    </section>

    <section>
      <h4>Dane do przelewu</h4>
      <label>
        <span>Konto</span>
        <input type="text" v-model="recipient.account" placeholder="np. 80 000 0000 0000 0000 0000 1234" v-auto-select/>
      </label>
      <label>
        <span>Tytuł</span>
        <textarea v-model="recipient.title" placeholder="np. Numer faktury"></textarea>
      </label>
      <label>
        <span>Adres</span>
        <textarea v-model="recipient.address" placeholder="np. ul. Św. Marcin" v-auto-select></textarea>
      </label>
    </section>
  </div>
</template>

<script>
  import DatePicker from '@components/DatePicker/DatePicker';
  import AmountInput from '@components/Inputs/AmountInput';
  import moment from 'moment';

  export default {
    components: {
      AmountInput,
      DatePicker
    },

    props: {
      recipient: {
        default: () => ({
          id: null,
          name: '',
          description: '',
          title: '',
          address: '',
          account: '',
          cost: null,
          deadline: new Date(),
          active: true
        })
      }
    },

    computed: {
      deadline: {
        set(val) {
          this.recipient.deadline = val.toDate();
        },

        get() {
          return moment.utc(this.recipient.deadline);
        }
      }
    },

    methods: {
      setDate(date) {
        this.deadline = date;
      }
    }
  };
</script>
