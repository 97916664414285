<template>
  <SubmitModal ref="modal" :submit="save">
    <template slot="cancel">Anuluj</template>
    <template slot="header">Nowy paragon</template>
    <template slot="submit">Dodaj</template>
    <template slot="actions">
      <button type="button" @click="detailedAdd">Edytuj produkty</button>
    </template>
    <QuickReceiptForm slot="body" slot-scope="{ item }" :quick-receipt="item" ref="form" class="quick-receipt-modal"/>
  </SubmitModal>
</template>

<style lang="scss">
.quick-receipt-modal {
  min-height: 50vh;
  min-width: 30vw;
}
</style>

<script>
import { Modals } from '@components/consts';
import SubmitModal from '@components/Modal/SubmitModal';
import QuickReceiptForm from './QuickReceiptForm';
import moment from 'moment';

export default {
  inject: [
    '$api',
    'showModal',
    'showSuccessMessage',
    'showFailMessage'
  ],

  components: {
    QuickReceiptForm,
    SubmitModal
  },

  methods: {
    detailedAdd() {
      const quickReceipt = this.$refs.form.serialize();

      this.$refs.modal.hide();
      this.$router.push({
        path: '/receipts/new',
        query: quickReceipt
      });
    },

    async show(config = {}) {
      const shop = config.shopId ? await this.$api.getShop(config.shopId) : null

      return this.$refs.modal.show({
        shop,
        date: moment.utc(config.date),
        name: config.name || 'Zbiorczy',
        total: config.total ?? 0,
        tags: config.tags || shop?.tagList || ''
      });
    },

    async save() {
      const quickReceipt = this.$refs.form.serialize();

      if (!quickReceipt.shopId || !quickReceipt.total || !quickReceipt.date || !quickReceipt.name) {
        return Promise.reject();
      }

      const receipts = await this.$api.getReceipts({
        total: quickReceipt.total,
        shopId: quickReceipt.shopId,
        date: quickReceipt.date
      });

      if (receipts.meta.total > 0) {
        const confirmation = await this.showModal(Modals.RECEIPT_ADD_CONFIRM, quickReceipt);

        if (!confirmation) {
          return Promise.reject();
        }
      }

      return this.$api.saveReceipt(quickReceipt);
    }
  }
};
</script>
