<template>
  <table>
    <tr>
      <td>Tytuł</td>
      <td class="preserve">{{ bill.title }}</td>
    </tr>
    <tr>
      <td>Konto</td>
      <td>{{ bill.account }}</td>
    </tr>
    <tr>
      <td>Adres</td>
      <td class="preserve">{{ bill.address }}</td>
    </tr>
    <tr v-if="payDate">
      <td>Data opłacenia</td>
      <td class="preserve">{{ payDate }}</td>
    </tr>
  </table>
</template>

<script>
  import moment from 'moment';

  export default {
    props: {
      bill: {
        type: Object
      }
    },

    computed: {
      payDate() {
        if (!this.bill || !this.bill.payDate) {
          return null;
        }

        return moment.utc(this.bill.payDate).format('LL');
      }
    }
  };
</script>

<style scoped lang="scss">
  .preserve {
    white-space: pre;
  }
</style>
