<template>
  <SubmitModal ref="modal" :submit="save">
    <template slot="cancel">Anuluj</template>
    <template slot="header" slot-scope="{ item }">{{ item.id ? 'Edytuj rachunek' : 'Nowy rachunek' }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.id ? 'Zapisz zmiany' : 'Dodaj rachunek' }}</template>
    <BillForm slot="body" slot-scope="{ item }" :bill="item"/>
  </SubmitModal>
</template>

<script>
  import BillForm from './BillForm';
  import SubmitModal from '@components/Modal/SubmitModal';
  import { pick } from 'lodash';
  import { getDeadlineInThisMonth } from '@components/Bills/utils';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
      BillForm,
    },

    methods: {
      async show(item) {
        const isNewReceipt = !item?.id;
        const isEmptyReceipt = isNewReceipt && !item?.name;

        const recipient = isEmptyReceipt && item?.recipientId && await this.getRecipientAutoFillData(item?.recipientId);

        const bill = Object.assign({
          id: null,
          recipientId: null,
          name: '',
          description: '',
          title: '',
          address: '',
          account: '',
          cost: null,
          deadline: null,
          status: false
        }, recipient, item);

        if (isNewReceipt) {
          bill.deadline = getDeadlineInThisMonth(bill);
        }

        return this.$refs.modal.show(bill);
      },

      save(item) {
        return this.$api.saveBill(item);
      },

      async getRecipientAutoFillData(recipientId) {
        const recipient = await this.$api.getRecipientListItem(recipientId);

        if (!recipient) {
          return {};
        }

        return pick(recipient, [
          'name',
          'description',
          'title',
          'address',
          'cost',
          'account',
          'deadline'
        ]);
      }
    }
  };
</script>
