var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubmitModal',{ref:"modal",attrs:{"submit":_vm.confirm},scopedSlots:_vm._u([{key:"cancel",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.no))]}},{key:"header",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.header))]}},{key:"submit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.yes))]}},{key:"body",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('p',[_vm._v(_vm._s(item.body))])])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }