import slug from 'slug';

export default function slugize(string) {
  return slug(string, {
    replacement: '-',
    remove: null,
    lower: true,
    charmap: slug.charmap,
    multicharmap: slug.multicharmap
  });
}
