<template>
  <ItemSummary
    :name="payer.name"
    :description="payer.defaultDescription"
    :amount="payer.defaultIncome || '-'"
  />
</template>

<script>
  import ItemSummary from '@components/List/ItemSummary';

  export default {
    components: { ItemSummary },
    props: ['payer']
  };
</script>
