<template>
  <div class="item-summary">
    <div class="item-summary__row">
      <div class="item-summary__meta">
        <slot name="name">
          <strong class="item-summary__name">{{ name }}</strong>
        </slot>
        <small v-if="description" class="item-summary__description">{{ description }}</small>
      </div>

      <div class="item-summary__details">
        <strong class="item-summary__amount">{{ formattedAmount }}</strong>
        <small v-if="date" class="item-summary__date">{{ formattedDate }}</small>
        <small v-if="quantity" class="item-summary__quantity">{{ quantity }}</small>
      </div>
    </div>

    <div class="item-summary__row item-summary__tags" v-if="tags && tags.length">
      <AssignedTagsList :tags="tags" :can-remove="false" :can-edit="false"/>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import AssignedTagsList from '@components/Tags/AssignedTagsList';

  export default {
    components: { AssignedTagsList },
    props: {
      name: String,
      description: String,
      amount: [String, Number],
      date: [String, Date],
      quantity: [String, Number],
      tags: Array
    },

    computed: {
      formattedAmount() {
        if (typeof this.amount !== 'number') {
          return this.amount;
        }

        return `${this.$options.filters.currency(this.amount)} zł`;
      },

      formattedDate() {
        return moment.utc(this.date).format('LL');
      }
    }
  };
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.item-summary {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
  }

  a:hover > & {
    color: $regularColor;
  }

  &__meta,
  &__details {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  &__details {
    align-items: flex-end;
  }

  &__date,
  &__quantity,
  &__description {
    font-size: 0.8em;
    margin-top: 4px;
  }

  &__tags {
    margin-top: 0.4em;
    font-size: 0.7em;
  }
}
</style>
