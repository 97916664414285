<template>
  <div class="list-item">
    <div class="list-item-header">
      <div class="list-item-summary list-item-interactive" @click="showDetails">
        <slot name="summary"/>
      </div>
      <slot name="actions"/>
    </div>

    <div class="list-item-details" v-show="detailsVisible">
      <slot name="details"/>
    </div>

    <slot name="modals"/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        detailsVisible: false
      };
    },

    methods: {
      showDetails() {
        this.detailsVisible = !this.detailsVisible;
      }
    }
  };
</script>
