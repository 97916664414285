<template>
  <Modal ref="modal" v-if="item" @submit="onSubmit" @close="hide">
    <slot name="header" :item="item" slot="header">Uwaga!</slot>
    <slot name="body" :item="item" slot="body" />
    <template slot="footer">
      <button tabindex="2" type="button" class="cancel" @click="hide"><slot name="cancel" :item="item">Anuluj</slot></button>
      <slot name="actions"/>
      <spinning-button tabindex="1" ref="submit" class="primary" :loading="loading"><slot name="submit" :item="item">Wyślij</slot></spinning-button>
    </template>
  </Modal>
</template>

<script>
import { clone } from 'lodash';
import Modal from '@components/Modal/Modal';
import SpinningButton from '@components/SpinningButton/SpinningButton';

export default {
  inject: [
    'showFailMessage'
  ],

  components: {
    SpinningButton,
    Modal
  },

  props: {
    submit: {
      type: Function,
      default() {
        return Promise.reject(new Error('Not implemented'));
      }
    },
    getFailMessage: {
      type: Function,
      default(error) {
        return error.message;
      }
    }
  },

  data() {
    return {
      loading: false,
      resolve: null,
      item: null
    };
  },

  methods: {
    show(item) {
      return new Promise((resolve) => {
        this.item = clone(item);
        this.resolve = resolve;
      })
        .finally(() => {
          this.resolve = null;
        });
    },

    hide() {
      this.item = null;
      this.resolve(false);
      this.$emit('close');
    },

    onSubmit() {
      this.loading = true;

      Promise.resolve()
        .then(() => this.submit(this.item))
        .then((result) => {
          if (!this.resolve) {
            return;
          }

          this.resolve(result);
          this.item = null;
        }, (error) => {
          this.$refs.submit.shake();

          if (error) {
            this.showFailMessage(this.getFailMessage(error));
          }

          this.$refs.modal.focus();
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
