<template>
  <div class="list-item-action list-item-interactive" :title="status ? disableLabel : enableLabel">
    <i class="fa" :class="icon"></i>
  </div>
</template>

<script>
  export default {
    props: {
      status: {
        default: false
      },
      enableLabel: {
        default: 'Aktywuj'
      },
      disableLabel: {
        default: 'Deaktywuj'
      }
    },

    computed: {
      icon() {
        return this.status ? 'fa-times-circle' : 'fa-check-circle-o';
      }
    }
  };
</script>
