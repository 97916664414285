<template>
  <AutoComplete
    ref="input"
    :selected-item="selected"
    :items="items"
    :allow-not-found="allowNotFound"
    :placeholder="placeholder"
    :select-first="selectFirst"
    @select="$emit('select', arguments[0], arguments[1])"
    @select-new="$emit('select-new', arguments[0], arguments[1])"
    @select-empty="$emit('select-empty', arguments[0])"
    @not-found="$emit('not-found', arguments[0], arguments[1])" />
</template>

<script>
  import AutoComplete from '@components/AutoComplete/AutoComplete';

  function toAutoCompleteItem(payer) {
    return {
      data: payer,
      label: payer.name
    };
  }

  export default {
    inject: ['$api'],

    components: {
      AutoComplete
    },

    props: {
      selectFirst: {
        default: false
      },
      allowNotFound: {
        default: true
      },
      placeholder: {
        default: 'np. Cognifide'
      },
      payer: {
        default: null
      }
    },

    data() {
      return {
        payers: []
      };
    },

    computed: {
      items() {
        return this.payers.map(toAutoCompleteItem);
      },

      selected() {
        if (!this.payer) {
          return null;
        }

        if (this.payer.id) {
          const payer = this.payers.find((payer) => payer.id === this.payer.id);

          if (payer) {
            return toAutoCompleteItem(payer);
          }
        }

        return toAutoCompleteItem(this.payer);
      }
    },

    created() {
      this.$api.getPayersList()
        .then((payers) => (this.payers = payers));
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      }
    }
  };
</script>
