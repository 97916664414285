<template>
  <Page :loading="loading">
    <h1 slot="header">Produkty</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/receipts">Paragony</RelatedItem>
        <RelatedItem to="/tags">Tagi</RelatedItem>
      </Related>

      <SummaryFilter
        :summary-data="summary"
        :period="period"
        :month="month"
        :year="year"
        :date="date"
      />

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="receipt date" as-default="desc">Data paragonu</Sorter>
        <Sorter by="name">Nazwa</Sorter>
        <Sorter by="total">Kwota</Sorter>
        <Sorter by="cost">Cena</Sorter>
        <Sorter by="quantity">Ilość</Sorter>
        <Sorter by="receipt shop name">Sklep</Sorter>
        <Sorter by="created">Data utworzenia</Sorter>
      </div>
    </template>

    <template slot="body" v-if="loadingItems">
      <Loader/>
    </template>

    <template slot="body" v-else>
      <List :items="items" :meta="meta" :groups="groups">
        <template slot="group-header" slot-scope="{ item, group }">
          <h4 class="group-by-date">
            <template v-if="group.link">
              <router-link :to="group.link">{{ group.label }}</router-link>
            </template>
            <template v-else>
              {{ group.label }}
            </template>
          </h4>
        </template>
        <ProductItem slot-scope="{ item }"
                     :product="item"
                     @remove="load()"
                     @updated-shop="load()"
        />
      </List>
    </template>
  </Page>
</template>

<script>
  import List from '@components/List/List';
  import Loader from '@components/Loader';
  import { ListMixin } from '@mixins';
  import Page from '@components/Page';
  import SummaryFilter from '@components/Summary/SummaryFilter';
  import Sorter from '@components/Sorter/Sorter';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';
  import ProductItem from '@components/Products/ProductItem';
  import { createProductGroupingFunction } from '@components/Products/grouping';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    mixins: [
      ListMixin
    ],

    components: {
      ProductItem,
      RelatedItem,
      Related,
      SummaryFilter,
      Sorter,
      Page,
      List,
      Loader
    },

    computed: {
      groups() {
        const handler = createProductGroupingFunction(this.order);

        if (!handler) {
          return this.items.map(() => null);
        }

        const { itemGroupMap } = handler(this.items)

        return this.items.map((item) => itemGroupMap.get(item));
      }
    },

    methods: {
      fetch(query) {
        if (!query.order) {
          query.order = 'receipt date desc';
        }

        return this.$api.getProducts(query);
      },

      fetchSummary(query) {
        return this.$api.getProductsSummary(query);
      }
    }
  };
</script>
