<template>
  <div class="shop-form">
    <section>
      <label>
        <span>Nazwa sklepu</span>
        <input type="text" v-model="shop.name" placeholder="np. Biedronka" v-auto-select v-focus/>
      </label>

      <label>
        <span>Adres</span>
        <input type="text" v-model="shop.address" placeholder="np. Grochowska 20" v-auto-select/>
      </label>

      <label>
        <span>Domyślne Tagi</span>
        <TagInput :tags="shop.tagList" @change="shop.tagList = arguments[0]"/>
      </label>

      <label>
        <span>Miasto</span>
        <input type="text" v-model="shop.city" placeholder="np. Poznań" v-auto-select/>
      </label>
    </section>
  </div>
</template>

<script>
  import TagInput from '@components/TagInput/TagInput';

  export default {
    components: {
      TagInput
    },

    props: {
      shop: {
        default() {
          return {
            id: null,
            name: '',
            address: '',
            city: '',
            tagList: ''
          };
        }
      }
    }
  };
</script>
