<template>
  <div class="bill-form">
    <section>
      <h4>Dane podstawowe</h4>
      <label>
        <span>Odbiorca</span>
        <recipient-autocomplete :recipient="recipient" placeholder="np. Inea" :auto-focus="false"
                                @select-new="recipient = { name: arguments[0] }"
                                @select="fill"/>
      </label>
      <label>
        <span>Kwota</span>
        <AmountInput :amount.sync="bill.cost"/>
      </label>
      <label>
        <span>Termin</span>
        <date-picker :start-date="deadline" @select="setDate"/>
      </label>
      <label>
        <span>Opis</span>
        <textarea v-model="bill.description" placeholder="np. rachunek za gaz" v-auto-select></textarea>
      </label>
    </section>

    <section>
      <h4>Dane do przelewu</h4>
      <label>
        <span>Konto</span>
        <input type="text" v-model="bill.account" placeholder="np. 80 000 0000 0000 0000 0000 1234"
               v-auto-select/>
      </label>
      <label>
        <span>Tytuł</span>
        <textarea v-model="bill.title" placeholder="np. Rachunek za wrzesień" v-auto-select></textarea>
      </label>
      <label>
        <span>Adres</span>
        <textarea v-model="bill.address" placeholder="np. ul. Św. Marcin" v-auto-select></textarea>
      </label>
    </section>
  </div>
</template>

<script>
  import AmountInput from '../Inputs/AmountInput';
  import DatePicker from '../DatePicker/DatePicker';
  import RecipientAutocomplete from './RecipientAutocomplete';
  import moment from 'moment';
  import { mergeWith } from 'lodash';

  export default {
    name: 'bill-form',

    components: {
      AmountInput,
      RecipientAutocomplete,
      DatePicker
    },

    props: {
      bill: {
        default: () => ({
          id: null,
          recipientId: null,
          name: '',
          description: '',
          title: '',
          address: '',
          account: '',
          cost: null,
          deadline: new Date(),
          status: false
        })
      }
    },

    computed: {
      deadline: {
        set(val) {
          this.bill.deadline = val.toDate();
        },

        get() {
          return moment.utc(this.bill.deadline);
        }
      },

      recipient: {
        get() {
          return {
            id: this.bill.recipientId,
            name: this.bill.name
          };
        },

        set(recipient) {
          this.bill.name = recipient.name;
          this.bill.recipientId = recipient.id;
        }
      }
    },

    methods: {
      setDate(date) {
        this.deadline = date;
      },

      fill(recipient) {
        if (!recipient || this.bill.recipientId === recipient.id) {
          return;
        }

        const today = moment.utc();

        this.bill.recipientId = recipient.id;

        mergeWith(this.bill, recipient, (obj, src, key) => {
          switch (key) {
            case 'id':
            case 'active':
              return obj;
            case 'name':
              return src;
            case 'deadline':
              return moment
                .utc(src)
                .month(today.month())
                .year(today.year())
                .toISOString();
            default:
              return src !== '' ? src : '';
          }
        });
      }
    }
  };
</script>
