<template>
  <SubmitModal ref="modal" :submit="save">
    <template slot="cancel">Anuluj</template>
    <template slot="header" slot-scope="{ item }">{{ item.id ? 'Edytuj sklep' : 'Nowy sklep' }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.id ? 'Zapisz zmiany' : 'Dodaj sklep' }}</template>
    <ShopForm slot="body" slot-scope="{ item }" :shop="item" />
  </SubmitModal>
</template>

<script>
import SubmitModal from '@components/Modal/SubmitModal';
import ShopForm from '@components/Shops/ShopForm';

export default {
  inject: [
    '$api',
    'showSuccessMessage',
    'showFailMessage'
  ],

  components: {
    ShopForm,
    SubmitModal
  },

  methods: {
    async show(shop) {
      if (typeof shop === 'number') {
        return this.$api.getShop(shop)
          .then(this.show);
      }

      return this.$refs.modal.show(Object.assign({
        id: null,
        name: '',
        address: '',
        city: 'Poznań',
        tagList: ''
      }, shop));
    },

    save(item) {
      return this.$api.saveShop(item);
    }
  }
};
</script>
