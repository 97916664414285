<template>
  <ItemSummary
    :name="income.name"
    :description="income.description"
    :amount="income.income"
    :date="income.date"
  />
</template>

<script>
  import ItemSummary from '../List/ItemSummary';

  export default {
    components: { ItemSummary },
    props: ['income']
  };
</script>
