<template>
  <div class="recent-items">
    <header>
      <h3>Rachunki do zapłacenia</h3>
      <button @click="showBillModal">
        <i class="fa fa-plus-circle"></i>Nowy
      </button>
    </header>

    <template v-for="bill in bills">
      <template v-if="billGroups[bill.month][0] === bill">
        <h4 class="group-by-date" :key="`${bill.id}-group`">
          <router-link :to="getBillMonthUrl(bill)">{{ bill.deadline | moment('MMMM YYYY') }}</router-link>
        </h4>
      </template>
      <BillItem :key="bill.id" :bill="bill" @remove="load" @paid="load"/>
    </template>
  </div>
</template>

<script>
  import BillItem from './BillItem';
  import { groupBy } from 'lodash';
  import moment from 'moment';
  import { Modals } from '@components/consts';

  export default {
    name: 'bills-to-pay',

    inject: [
      '$api',
      'showSuccessMessage',
      'showModal'
    ],

    components: {
      BillItem,
    },

    data() {
      return {
        bills: []
      };
    },

    computed: {
      billGroups() {
        return groupBy(this.bills, 'month');
      }
    },

    created() {
      this.load();
    },

    methods: {
      showBillModal() {
        this.showModal(Modals.BILL_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano rachunek ${result.name}`);
              this.load();
            }
          });
      },

      load() {
        this.$api.getUpcomingBills()
          .then((bills) => {
            this.bills = bills.map((bill) => {
              bill.month = moment.utc(bill.deadline).month();

              return bill;
            });
          });
      },

      getBillMonthUrl(bill) {
        return `/bills?period=${moment(bill.deadline).format('YYYY-MM')}`;
      }
    }
  };
</script>
