<template>
  <div>
    <ShopInput ref="shop"
               :shop="shop"
               @select="setShop"
               @not-found="createShop" />
    <DatePicker ref="date" :start-date="date" @select="setDate" />
    <ProductAutocomplete :auto-focus="false"
                         :product="name"
                         @select="name = arguments[0]"
                         @select-new="name = arguments[0]" />
    <AmountInput :amount.sync="total" />
    <TagInput :tags="tags" @change="tags = arguments[0]" />
  </div>
</template>

<script>
  import moment from 'moment';
  import { Modals } from '@components/consts';
  import TagInput from '@components/TagInput/TagInput';
  import ShopInput from '@components/ShopInput/ShopInput';
  import DatePicker from '@components/DatePicker/DatePicker';
  import AmountInput from '@components/Inputs/AmountInput';
  import ProductAutocomplete from '@components/ProductAutocomplete/ProductAutocomplete';

  export default {
    components: {
      AmountInput,
      ProductAutocomplete,
      DatePicker,
      ShopInput,
      TagInput
    },

    inject: [
      'showModal'
    ],

    props: {
      quickReceipt: Object
    },

    data() {
      return {
        shop: this.quickReceipt.shop || null,
        name: this.quickReceipt.name || 'Zbiorczy',
        total: this.quickReceipt.total ?? 0,
        date: this.quickReceipt.date || moment.utc(),
        tags: this.quickReceipt.tags || ''
      };
    },

    methods: {
      serialize() {
        return {
          shopId: this.shop?.id,
          date: this.date.toISOString(),
          name: this.name,
          tags: this.tags,
          total: this.total
        };
      },

      setDate(date) {
        this.date = date;
      },

      setShop(shop) {
        this.shop = shop;
        this.tags = shop ? shop.tagList : '';
        this.name = 'Zbiorczy';
        this.total = 0;
      },

      createShop(name, event) {
        event.preventDefault();

        this.showModal(Modals.SHOP_FORM, {
          name
        })
          .then((shop) => {
            if (shop) {
              this.$refs.shop.update();
              this.setShop(shop);

              if (this.$refs.date) {
                this.$refs.date.focus();
              }
            } else {
              this.$refs.shop.focus();
            }
          });
      }
    },

    mounted() {
      if (this.shop) {
        this.$refs.date.focus();
      }
    }
  };
</script>
