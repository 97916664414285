<template>
  <section class="messages">
    <div v-if="count" class="messages-panel">
      <div class="messages-summary">
        <p><a href="#show-messages" @click.prevent="expanded = true"><i class="fa fa-eye"></i> Zdarzenia: {{ count }}</a></p>
      </div>
    </div>

    <modal v-if="expanded" class="messages-list" @close="expanded = false">
      <template slot="header">Ostatnie zdarzenia</template>

      <ul class="messages-list" slot="body">
        <li class="message-item" v-for="message in messages" :key="message.timestamp" :class="'message-' + message.type">
          <p class="timestamp">{{ message.timestamp | ago }}</p>
          <router-link v-if="message.link" :to="message.link">
            <div v-html="message.content"/>
          </router-link>
          <div v-else v-html="message.content" />
        </li>
      </ul>
    </modal>

    <div class="messages-place">
      <transition-group name="message-popup" tag="ul">
        <li v-for="message in current" class="message-popup" :key="message.timestamp" :class="'message-' + message.type">
          <router-link v-if="message.link" :to="message.link">
            <div v-html="message.content"/>
          </router-link>
          <div v-else v-html="message.content" />
        </li>
      </transition-group>
    </div>
  </section>
</template>

<style lang="scss" src="./Messages.scss"/>

<script>
  import Modal from '../Modal/Modal';

  export default {
    name: 'messages',

    props: {
      duration: {
        default: 4000
      },
      currentMax: {
        default: 5
      }
    },

    components: {
      Modal
    },

    data() {
      return {
        messages: [],
        current: [],
        expanded: false
      };
    },

    computed: {
      count() {
        return this.messages.length;
      }
    },

    created() {
      this.$router.afterEach(() => (this.expanded = false));
      this.$root.$on('message', (payload) => this.showMessage(payload));
    },

    methods: {
      showMessage({ type = 'info', content, link } = {}) {
        const message = {
          timestamp: Date.now(),
          type,
          link,
          content
        };

        if (this.current.length >= this.currentMax) {
          this.current.shift();
        }

        this.current.unshift(message);
        this.messages.push(message);

        setTimeout(() => {
          const index = this.current.indexOf(message);

          this.current.splice(index, 1);
        }, this.duration);
      }
    }
  };
</script>
