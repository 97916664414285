<template>
  <div class="modals">
    <ConfirmModal :ref="Modals.CONFIRM" @error="showError"/>
    <QuickReceiptModal :ref="Modals.RECEIPT_FORM" @error="showError"/>
    <IncomeModal :ref="Modals.INCOME_FORM" @error="showError"/>
    <IncomeConfirmDelete :ref="Modals.INCOME_DELETE_CONFIRM" @error="showError"/>
    <PayerConfirmDelete :ref="Modals.PAYER_DELETE_CONFIRM" @error="showError"/>
    <PayerModal :ref="Modals.PAYER_FORM" @error="showError"/>
    <RecipientModal :ref="Modals.RECIPIENT_FORM" @error="showError"/>
    <RecipientConfirmDelete :ref="Modals.RECIPIENT_DELETE_CONFIRM" @error="showError"/>
    <BillModal :ref="Modals.BILL_FORM" @error="showError"/>
    <BillConfirmDelete :ref="Modals.BILL_DELETE_CONFIRM" @error="showError"/>
    <ShopModal :ref="Modals.SHOP_FORM" @error="showError"/>
    <ShopConfirmDelete :ref="Modals.SHOP_DELETE_CONFIRM" @error="showError"/>
    <TagModal :ref="Modals.TAG_FORM" @error="showError"/>
    <ConfirmAdding :ref="Modals.RECEIPT_ADD_CONFIRM" @error="showError"/>
    <RepeatBillModal :ref="Modals.REPEAT_BILL" @error="showError"/>
  </div>
</template>

<script>
import { Modals } from '@components/consts';
import PayerConfirmDelete from '@components/Income/Payers/PayerConfirmDelete';
import PayerModal from '@components/Income/Payers/PayerModal';
import IncomeModal from '@components/Income/IncomeModal';
import IncomeConfirmDelete from '@components/Income/IncomeConfirmDelete';
import RecipientModal from '@components/Bills/RecipientModal';
import RecipientConfirmDelete from '@components/Bills/RecipientConfirmDelete';
import BillModal from '@components/Bills/BillModal';
import BillConfirmDelete from '@components/Bills/BillConfirmDelete';
import RepeatBillModal from '@components/Bills/RepeatBillModal';
import ShopModal from '@components/Shops/ShopModal';
import ShopConfirmDelete from '@components/Shops/ShopConfirmDelete';
import TagModal from '@components/Tags/TagModal';
import ConfirmModal from '@components/Modal/Confirm';
import ConfirmAdding from '@components/Modal/ConfirmAdding';
import QuickReceiptModal from '@components/QuickReceipt/QuickReceiptModal';

export default {
  components: {
    ConfirmModal,
    QuickReceiptModal,
    ConfirmAdding,
    TagModal,
    ShopConfirmDelete,
    ShopModal,
    BillConfirmDelete,
    BillModal,
    RepeatBillModal,
    RecipientConfirmDelete,
    RecipientModal,
    IncomeConfirmDelete,
    IncomeModal,
    PayerModal,
    PayerConfirmDelete
  },

  inject: [
    'showSuccessMessage',
    'showFailMessage'
  ],

  data() {
    return {
      Modals
    };
  },

  methods: {
    showModal(ref, ...args) {
      return this.$refs?.[ref]?.show?.(...args);
    },

    showError(error) {
      this.showFailMessage(error.message);
    }
  }
}
</script>
