<template>
  <Modal>
    <template slot="header">Serwer niedostępny</template>

    <div slot="body">
      <p>Nie udało się połączyć z serwerem.</p>
    </div>

    <div class="footer" slot="footer">
      <button class="primary" @click="tryAgain">Spróbuj ponownie</button>
    </div>
  </Modal>
</template>

<script>
  import Modal from '@components/Modal/Modal';

  export default {
    components: {
      Modal
    },

    inject: [
      '$api'
    ],

    methods: {
      tryAgain() {
        this.$api.status();
      }
    }
  };
</script>
