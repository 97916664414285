<template>
  <div class="shop-input">
    <AutoComplete ref="input"
                  tabindex="2"
                  class="shop-input-autocomplete"
                   :selected-item="selected"
                   :auto-focus="autoFocus"
                   :items="items"
                   :item-cls="ShopInputItem"
                   :allow-not-found="allowNotFound"
                   :placeholder="placeholder"
                   :select-first="selectFirst"
                   @select="$emit('select', arguments[0], arguments[1])"
                   @select-new="$emit('select-new', arguments[0], arguments[1])"
                   @select-empty="$emit('select-empty', arguments[0])"
                   @not-found="$emit('not-found', arguments[0], arguments[1])"
    />
    <div class="shop-input-add button" v-if="canAddShop" @click.prevent="$emit('not-found', $refs.input.query, arguments[0])" tabindex="1">+</div>
  </div>
</template>

<style lang="scss">
.shop-input {
  display: flex;
  align-items: center;
  justify-items: center;
}

.shop-input-autocomplete {
  flex: 1;
}

.shop-input-add {
  margin-left: .5em;
}
</style>

<script>
  import AutoComplete from '@components/AutoComplete/AutoComplete';
  import ShopInputItem from '@components/ShopInput/ShopInputItem';
  import { getAddress, getShopLabel } from '@utils/shops';

  function toAutoCompleteItem(shop) {
    const address = getAddress(shop);
    const label = getShopLabel(shop);

    return {
      data: shop,
      order: shop.receiptCount,
      name: shop.name,
      label,
      address
    };
  }

  export default {
    name: 'shop-input',

    inject: [
      '$api',
      'showModal'
    ],

    components: {
      AutoComplete
    },

    props: {
      selectFirst: {
        default: true
      },
      allowNotFound: {
        default: false
      },
      placeholder: {
        default: 'Sklep'
      },
      shop: {
        type: Object,
        default: null
      },
      autoFocus: Boolean
    },

    data() {
      return {
        shops: [],
        ShopInputItem
      };
    },

    computed: {
      items() {
        return this.shops.map(toAutoCompleteItem);
      },

      selected() {
        return this.shop ? toAutoCompleteItem(this.shop) : null;
      },

      canAddShop() {
        return this.$listeners['not-found'];
      }
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },

      update() {
        this.$api.getShopsList()
          .then((shops) => (this.shops = shops));
      }
    },

    created() {
      this.update();
    }
  };
</script>
