import { EventEmitter } from 'events';
import { toQueryParams } from './utils';

const REQUEST_SUCCESS = 200;
const REQUEST_FORBIDDEN = 403;

export const Events = {
  LOG_IN: 'logged-in',
  LOG_OUT: 'logged-out',
  SERVER_STATUS: 'server-status'
};

export default class API extends EventEmitter {
  constructor(endpoint) {
    super();
    this.endpoint = endpoint;
    this.user = null;
    this.currentGroup = null;
    this.authPromise = this.status()
      .then((status) => {
        if (!status.user) {
          throw new Error('Not authenticated');
        }

        this._postLogin(status.user);
      });

    this.serverAvailable = true;
    this.cachedProductList = null;
    this.cachedShopList = null;
    this.cachedRecipientsList = null;
    this.cachedPayersList = null;

    this.tagListPromise = null;
  }

  static Events = Events;

  _request(url, options) {
    return fetch(`${this.endpoint}/${url}`, Object.assign({
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      credentials: 'include'
    }, options))
      .then((response) => {
        if (!this.serverAvailable) {
          this.serverAvailable = true;
          this.emit(Events.SERVER_STATUS, true);
        }

        return response;
      }, (error) => {
        if (this.serverAvailable) {
          this.serverAvailable = false;
          this.emit(Events.SERVER_STATUS, false);
        }

        throw error;
      });
  }

  login(credentials) {
    this.authPromise = this._request('login', {
      method: 'POST',
      body: JSON.stringify({
        username: credentials.username,
        password: credentials.password
      })
    })
      .then((response) => {
        if (response.status !== REQUEST_SUCCESS) {
          throw new Error('Not authenticated');
        }

        return response.json();
      })
      .then((user) => this._postLogin(user))
      .catch((err) => {
        this._postLogout();

        throw err;
      });

    return this.authPromise;
  }

  _postLogin(user) {
    delete user.token;

    this.user = user;

    if (user.groups && user.groups.length === 1) {
      this.currentGroup = user.groups[0];
    }

    this.emit(Events.LOG_IN, this.user);

    return this.user;
  }

  _postLogout() {
    if (this.user !== null) {
      this.user = null;
      this.currentGroup = null;
      this.authPromise = Promise.reject(new Error('Not authenticated'));
      this.emit(Events.LOG_OUT);
    }

    return null;
  }

  logout() {
    if (!this.user) {
      return Promise.reject(new Error('Not authenticated'));
    }

    return this._request('logout')
      .then((response) => {
        if (response.status !== REQUEST_SUCCESS) {
          throw new Error('Not authenticated');
        }

        return this._postLogout();
      });
  }

  status() {
    return this.sendRequest('status');
  }

  sendRequest(url, data, extraOptions = {}) {
    const options = {};

    if (data) {
      options.method = 'POST';
      options.body = JSON.stringify(data);
    }

    return this._request(url, Object.assign(options, extraOptions))
      .then((response) => {
        if (response.status === REQUEST_FORBIDDEN) {
          this._postLogout();
          throw new Error('Not authorized');
        }

        if (response.status !== REQUEST_SUCCESS) {
          const contentType = response.headers.get('content-type');
          const isJson = contentType && contentType.indexOf('application/json') !== -1;

          if (!isJson) {
            throw new Error('Unknown server error');
          }

          return response.json()
            .then((json) => {
              throw new Error(json.message || 'Server error');
            });
        }

        return response.json();
      });
  }

  sendScopedRequest(url, data, options) {
    if (!this.currentGroup) {
      return Promise.reject(new Error('No active group'));
    }

    return this.sendRequest(`${this.currentGroup.name}/${url}`, data, options);
  }

  get isAuthenticated() {
    return this.user !== null;
  }

  getGroups() {
    return this.sendRequest('groups');
  }

  getShop(shopId) {
    return this.getShopsList()
      .then((list) => list.find((shop) => shop.id === shopId));
  }

  getShops(criteria) {
    return this.sendScopedRequest(`shops${toQueryParams(criteria)}`);
  }

  getShopsList() {
    if (this.cachedShopList) {
      return Promise.resolve(this.cachedShopList);
    }

    return this.sendScopedRequest('shops/list')
      .then((shops) => (this.cachedShopList = shops));
  }

  saveShop(shop) {
    if (shop && shop.tagList) {
      shop.tags = shop.tagList;
    }

    return this.sendScopedRequest('shops', shop)
      .then((savedShop) => {
        this.cachedShopList = null;
        this.tagListPromise = null;

        return savedShop;
      });
  }

  deleteShop(id) {
    return this.sendScopedRequest(`shops/${id}`, null, {
      method: 'DELETE'
    })
      .then((shop) => {
        this.cachedShopList = null;

        return shop;
      });
  }

  getProducts(criteria) {
    return this.sendScopedRequest(`products${toQueryParams(criteria)}`);
  }

  getProductsSummary(criteria) {
    return this.sendScopedRequest(`products/summary${toQueryParams(criteria)}`);
  }

  getProductsList() {
    if (this.cachedProductList) {
      return Promise.resolve(this.cachedProductList);
    }

    return this.sendScopedRequest('products/list')
      .then((products) => (this.cachedProductList = products));
  }

  getTags(criteria) {
    return this.sendScopedRequest(`tags${toQueryParams(criteria)}`);
  }

  getTagsList() {
    if (this.tagListPromise) {
      return this.tagListPromise;
    }

    this.tagListPromise = this.sendScopedRequest('tags/list');

    return this.tagListPromise;
  }

  saveTag(tag) {
    return this.sendScopedRequest('tags', tag)
      .then((savedTag) => {
        this.tagListPromise = null;

        return savedTag;
      })
  }

  saveReceipt(receipt) {
    if (receipt && receipt.products) {
      receipt.products.forEach((product) => {
        product.tags = product.tagList;
      });
    }

    return this.sendScopedRequest('receipts', receipt)
      .then((savedReceipt) => {
        this.cachedProductList = null;
        this.tagListPromise = null;

        return savedReceipt;
      });
  }

  deleteReceipt(id) {
    return this.sendScopedRequest(`receipts/${id}`, null, {
      method: 'DELETE'
    });
  }

  getReceipts(criteria) {
    return this.sendScopedRequest(`receipts${toQueryParams(criteria)}`);
  }

  getReceiptsSummary(criteria) {
    return this.sendScopedRequest(`receipts/summary${toQueryParams(criteria)}`);
  }

  getReceipt(id) {
    return this.sendScopedRequest(`receipts/${id}`);
  }

  getRecipientsList() {
    if (this.cachedRecipientsList) {
      return Promise.resolve(this.cachedRecipientsList);
    }

    return this.sendScopedRequest('recipients/list')
      .then((recipientsList) => (this.cachedRecipientsList = recipientsList));
  }

  getRecipientListItem(id) {
    return this.getRecipientsList()
      .then((list) => list.find((item) => item.id === id));
  }

  getBills(criteria) {
    return this.sendScopedRequest(`bills${toQueryParams(criteria)}`);
  }

  getBillsSummary(criteria) {
    return this.sendScopedRequest(`bills/summary${toQueryParams(criteria)}`);
  }

  saveBill(bill) {
    return this.sendScopedRequest('bills', bill);
  }

  repeatBill(id, config) {
    return this.sendScopedRequest(`bills/${id}/repeat`, config);
  }

  deleteBill(id) {
    return this.sendScopedRequest(`bills/${id}`, null, {
      method: 'DELETE'
    });
  }

  getUpcomingBills() {
    return this.sendScopedRequest('bills/upcoming');
  }

  getIncomes(criteria) {
    return this.sendScopedRequest(`incomes${toQueryParams(criteria)}`);
  }

  getIncomesSummary(criteria) {
    return this.sendScopedRequest(`incomes/summary${toQueryParams(criteria)}`);
  }

  saveIncome(income) {
    return this.sendScopedRequest('incomes', income);
  }

  deleteIncome(id) {
    return this.sendScopedRequest(`incomes/${id}`, null, {
      method: 'DELETE'
    });
  }

  getPayers(criteria) {
    return this.sendScopedRequest(`payers${toQueryParams(criteria)}`);
  }

  savePayer(payer) {
    return this.sendScopedRequest('payers', payer);
  }

  deletePayer(id) {
    return this.sendScopedRequest(`payers/${id}`, null, {
      method: 'DELETE'
    });
  }

  getPayerListItem(id) {
    return this.getPayersList()
      .then((list) => list.find((payer) => payer.id === id));
  }

  getPayersList() {
    if (this.cachedPayersList) {
      return Promise.resolve(this.cachedPayersList);
    }

    return this.sendScopedRequest('payers/list')
      .then((payersList) => (this.cachedPayersList = payersList));
  }

  getRecipients(criteria) {
    return this.sendScopedRequest(`recipients${toQueryParams(criteria)}`);
  }

  saveRecipient(recipient) {
    return this.sendScopedRequest('recipients', recipient)
      .then((savedRecipient) => {
        this.cachedRecipientsList = null;

        return savedRecipient;
      });
  }

  deleteRecipient(id) {
    return this.sendScopedRequest(`recipients/${id}`, null, {
      method: 'DELETE'
    });
  }
}
