<template>
  <div class="recipient-form">
    <section>
      <label>
        <span>Nazwa</span>
        <input type="text" v-model="payer.name" placeholder="np. Inea" v-auto-select/>
      </label>
      <label>
        <span>Kwota</span>
        <AmountInput :amount.sync="payer.defaultIncome"/>
      </label>
      <label>
        <span>Opis</span>
        <textarea v-model="payer.defaultDescription" placeholder="np. pracodawca" v-auto-select></textarea>
      </label>
    </section>
  </div>
</template>

<script>
  import AmountInput from '@components/Inputs/AmountInput';

  export default {
    components: { AmountInput },
    props: {
      payer: {
        default() {
          return {
            id: null,
            name: '',
            defaultDescription: '',
            defaultIncome: null
          };
        }
      }
    }
  };
</script>
