export default function toNumber(value) {
  if (typeof value === 'number' && !isNaN(value)) {
    return value;
  }

  if (!value || value === '.' || value === ',') {
    return 0;
  }

  return Number(value.replace(',', '.').replace(/ /g, ''));
}
