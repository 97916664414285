<template>
  <SubmitModal ref="modal" :submit="save">
    <template slot="cancel">Anuluj</template>
    <template slot="header" slot-scope="{ item }">{{ item.id ? 'Edytuj tag' : 'Nowy tag' }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.id ? 'Zapisz zmiany' : 'Dodaj tag' }}</template>
    <TagForm slot="body" slot-scope="{ item }" :tag="item" />
  </SubmitModal>
</template>

<script>
  import SubmitModal from '@components/Modal/SubmitModal';
  import TagForm from '@components/Tags/TagForm';

  export default {
    inject: [
      '$api',
      'showSuccessMessage',
      'showFailMessage'
    ],

    components: {
      TagForm,
      SubmitModal
    },

    methods: {
      show(item) {
        const tag = Object.assign({
          id: null,
          name: '',
          color: '',
          backgroundColor: ''
        }, item);

        return this.$refs.modal.show(tag);
      },

      save(tag) {
        return this.$api.saveTag(tag);
      }
    }
  };
</script>
