<template>
  <div class="list-item-action list-item-interactive" :title="title">
    <i class="fa" :class="icon"></i>
  </div>
</template>

<script>
  export default {
    props: ['icon', 'title']
  };
</script>
