<template>
  <SubmitModal ref="modal" :submit="save">
    <template slot="cancel">Anuluj</template>
    <template slot="header" slot-scope="{ item }">{{ item.id ? 'Edytuj odbiorcę' : 'Nowy odbiorca' }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.id ? 'Zapisz zmiany' : 'Dodaj odbiorcę' }}</template>
    <RecipientForm slot="body" slot-scope="{ item }" :recipient="item"/>
  </SubmitModal>
</template>

<script>
  import RecipientForm from '@components/Bills/RecipientForm';
  import SubmitModal from '@components/Modal/SubmitModal';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
      RecipientForm,
    },

    methods: {
      show(item) {
        return this.$refs.modal.show(Object.assign({
          id: null,
          name: '',
          description: '',
          title: '',
          address: '',
          account: '',
          cost: null,
          deadline: new Date(),
          active: true
        }, item));
      },

      save(item) {
        return this.$api.saveRecipient(item);
      }
    }
  };
</script>
