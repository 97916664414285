<template>
  <ListItem class="income-item">
    <template slot="summary">
      <ProductSummary :product="product"/>
    </template>

    <template slot="actions">
      <EditItemAction @click.native="editProduct" title="Edytuj nazwę i tagi produktu"/>
      <ListItemAction @click.native="editReceipt" icon="fa-file-text-o" title="Edytuj powiązany paragon"/>
      <ListItemAction @click.native="editShop" icon="fa-shopping-bag" title="Edytuj powiązany sklep"/>
      <RemoveItemAction @click.native="removeProduct"/>
    </template>
  </ListItem>
</template>

<script>
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import ListItem from '@components/List/ListItem';
  import ListItemAction from '@components/List/ListItemAction';
  import ProductSummary from '@components/Products/ProductSummary';
  import { Modals } from '@components/consts';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage',
      'showFailMessage'
    ],

    components: {
      ProductSummary,
      ListItemAction,
      ListItem,
      RemoveItemAction,
      EditItemAction
    },

    props: {
      product: {
        type: Object
      },
      showDestroy: {
        type: Boolean,
        default: true
      },
      showEdit: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      editProduct() {
        this.showFailMessage('Not implemented');
      },

      editReceipt() {
        this.$router.push(`/receipts/${this.product.receipt.id}`);
      },

      editShop() {
        this.showModal(Modals.SHOP_FORM, this.product.receipt.shop)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Zaktualizowano sklep ${result.name}`);
              this.$emit('updated-shop', result);
            }
          });
      },

      removeProduct() {
        this.showFailMessage('Not implemented');
      }
    }
  };
</script>
