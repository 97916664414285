import { MessageMixin } from '../../mixins';

export default {
  mixins: [
    MessageMixin
  ],

  methods: {
    recipientActiveUpdated(recipient) {
      const action = recipient.active ? 'Aktywowano' : 'Deaktywowano';

      this.showSuccessMessage(`${action} odbiorcę ${recipient.name}`);
    },

    recipientError(error) {
      this.showFailMessage(error.message);
    }
  }
};
