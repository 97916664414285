<template>
  <div class="product-summary">
    <div class="product-summary__place">
      <span class="product-summary__date">{{ date }}</span>, <span class="product-summary__shop">{{ shop }}</span>
    </div>
    <div class="product-summary__name">{{ product.name }}</div>
    <div class="product-summary__details">
      <TagsList class="product-summary__details-tags" :tags="product.tags"/>
      <span class="product-summary__details-cost">{{ costDetails }}</span>
      <span class="product-summary__details-total">{{ total }}</span>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../styles/variables.scss";

.product-summary {
  $margin: 0.5em;

  &__place {
    font-size: 0.8em;
    margin-bottom: $margin;
  }

  &__name, &__details {
    font-size: 1.1em;
  }

  &__details {
    margin-top: $margin;
    display: flex;
    align-items: center;
  }

  &__details-tags {
    flex: 1;
    font-size: 0.7em;
  }

  &__details-cost {
    font-size: 0.7em;
    margin-right: 0.5em;
  }
}
</style>

<script>
  import TagsList from '@components/Tags/AssignedTagsList';
  import { getShopLabel } from '@utils/shops';
  import moment from 'moment';
  export default {
    components: { TagsList },
    props: ['product'],
    computed: {
      shop() {
        return getShopLabel(this.product.receipt.shop);
      },
      date() {
        return moment.utc(this.product.receipt.date).format('LL');
      },
      costDetails() {
        return `(${this.product.quantity} x ${this.$options.filters.currency(this.product.cost)})`;
      },
      total() {
        return `${this.$options.filters.currency(this.product.total)} zł`;
      }
    }
  };
</script>
