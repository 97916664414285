import moment from 'moment';

export function parseReceipts(receipts) {
  const result = [];
  let lastDate = null;

  receipts.forEach((receipt) => {
    if (!lastDate || receipt.date !== lastDate.date) {
      lastDate = {
        date: receipt.date,
        label: moment.utc(receipt.date).format('dddd, LL'),
        link: `/receipts?period=${receipt.date}`,
        newLink: `/receipts/new?date=${receipt.date}`,
        receipts: []
      };
      result.push(lastDate);
    }

    lastDate.receipts.push(receipt);
  });

  return result;
}
