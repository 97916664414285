<template>
  <div class="list-item-action list-item-interactive" :title="title">
    <i class="fa fa-plus-circle"></i>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        default: 'Nowy'
      }
    }
  };
</script>
