<template>
  <ListItem class="receipt-item" :to="`receipts/${receipt.id}`">
    <template slot="summary">
      <ReceiptSummary :receipt="receipt"/>
    </template>

    <template slot="actions">
      <QuickNewItemAction @click.native="$emit('quick-add', receipt)" title="Nowy szybki paragon"/>
      <RemoveItemAction @click.native="$emit('destroy', receipt)"/>
    </template>
  </ListItem>
</template>

<script>
  import ReceiptSummary from '@components/ReceiptsList/ReceiptSummary';
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import QuickNewItemAction from '@components/List/QuickNewItemAction';
  import ListItem from '@components/List/ListItem';

  export default {
    components: {
      QuickNewItemAction,
      RemoveItemAction,
      ReceiptSummary,
      ListItem
    },

    props: ['receipt']
  };
</script>
