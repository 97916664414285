<template>
  <div class="tag-form">
    <section>
      <label>
        <span>Nazwa</span>
        <input type="text" v-model="tag.name" placeholder="np. Zakupy" v-auto-select v-focus />
      </label>

      <label>
        <span>Kolor tekstu</span>
        <input type="text" v-model="tag.color" placeholder="np. red" v-auto-select />
      </label>

      <label>
        <span>Kolor tła</span>
        <input type="text" v-model="tag.backgroundColor" placeholder="np. white" v-auto-select />
      </label>

      <label>
        <span>Podgląd</span>
        <AssignedTagsList :tags="[tag]" :can-see-details="false" :can-remove="false" :can-edit="false" />
      </label>
    </section>
  </div>
</template>

<script>
  import AssignedTagsList from '@components/Tags/AssignedTagsList';

  export default {
    components: {
      AssignedTagsList
    },

    inject: [
      'showModal'
    ],

    props: {
      tag: {
        default() {
          return {
            id: null,
            name: '',
            color: '',
            backgroundColor: ''
          };
        }
      }
    }
  };
</script>
