<template>
  <page :loading="loading">
    <template slot="actions">
      <shop-input ref="shop"
                  :shop="shop || (query ? { name: query } : null)"
                  :allow-not-found="true"
                  :select-first="false"
                  placeholder="Sklep / Szukaj"
                  @select="searchByShop"
                  @select-new="searchByQuery"
                  @select-empty="clearSearch"
      />
      <button type="button" @click="addShopReceipt" v-if="shopId">Dodaj paragon</button>
      <button type="button" @click="editShop" v-if="shopId">Edytuj sklep</button>
    </template>

    <h1 slot="header">Paragony</h1>

    <div slot="aside" class="receipt-summary">
      <Related>
        <RelatedItem to="/shops">Sklepy</RelatedItem>
        <RelatedItem to="/products">Produkty</RelatedItem>
        <RelatedItem to="/tags">Tagi</RelatedItem>
      </Related>

      <SummaryFilter :summary-data="summary"
                     :year="year"
                     :period="period"
                     :month="month"
                     :date="date"
                     :packable="true"
      />

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="date" as-default="desc">Data paragonu</Sorter>
        <Sorter by="created">Data utworzenia</Sorter>
        <Sorter by="productCount">Produktów</Sorter>
        <Sorter by="total">Kwota</Sorter>
      </div>
    </div>

    <template slot="body" v-if="loadingReceipts">
      <loader/>
    </template>

    <template slot="body" v-else>
      <p v-if="receiptsMeta && receiptsMeta.total === 0">Brak paragonów</p>

      <pagination v-if="receiptsMeta && receiptsMeta.total > receiptsMeta.limit"
                  :meta="receiptsMeta"/>

      <confirm-receipt-delete v-if="receiptToDelete"
                              :receipt="receiptToDelete"
                              :loading="deleting"
                              @confirm="deleteReceipt"
                              @close="receiptToDelete = null"
      />
      <template v-for="group in receipts">
        <template v-if="isGrouped">
          <h4 :key="group.id" v-if="isGrouped" class="group-by-date">
            <router-link :to="group.link">{{ group.label }}</router-link>
            <router-link :to="group.newLink">(+)</router-link>
          </h4>
        </template>
        <receipt-item v-for="receipt in group.receipts"
                      :key="receipt.id"
                      :receipt="receipt"
                      @click="goToReceipt"
                      @quick-add="quickAddReceipt"
                      @destroy="confirmReceiptDelete"
        />
      </template>


      <pagination v-if="receiptsMeta && receiptsMeta.total > receiptsMeta.limit"
                  :meta="receiptsMeta"/>
    </template>
  </page>
</template>

<script>
  import Pagination from '@components/Pagination/Pagination';
  import Loader from '@components/Loader';
  import Page from '@components/Page';
  import SummaryFilter from '@components/Summary/SummaryFilter';
  import Sorter from '@components/Sorter/Sorter';

  import ReceiptItem from './ReceiptItem';

  import { MessageMixin, DeleteReceiptMixin, ShopListMixin } from '@mixins';
  import { parsePeriodQuery, toPeriod } from '@utils/periods';
  import { parseReceipts } from './receiptsUtils';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';

  export default {
    name: 'receipts-list',

    inject: [
      '$api',
      'showModal',
    ],

    components: {
      RelatedItem,
      Related,
      Sorter,
      SummaryFilter,
      Page,
      ReceiptItem,
      Pagination,
      Loader
    },

    mixins: [
      MessageMixin,
      DeleteReceiptMixin,
      ShopListMixin
    ],

    data() {
      return Object.assign(
        {
          summary: null,
          order: 'date DESC',
          receipts: [],
          receiptsMeta: null,
          receiptToDelete: null,
          limit: 50,
          page: this.$route.query.page || 1,
          loadingReceipts: false,
          loading: false,
          deleting: false
        },
        parseQuery(this.$route.query),
        parsePeriodQuery(this.$route.query)
      );
    },

    watch: {
      $route(to) {
        const { year, month, date } = parsePeriodQuery(to.query);

        let reloadSummary = false;

        if (year !== this.year) {
          this.year = year;
        }

        if (month !== this.month) {
          this.month = month;
        }

        if (date !== this.date) {
          this.date = date;
        }

        if (to.query.q !== this.query) {
          this.query = to.query.q;
          reloadSummary = true;
        }

        if (to.query.order !== this.order) {
          this.order = to.query.order;
        }

        if (to.query.shopId !== this.shopId) {
          this.shopId = Number(to.query.shopId);
          reloadSummary = true;
        }

        if (to.query.tagId !== this.tagId) {
          this.tagId = Number(to.query.tagId);
          this.tagSlug = void 0;
          reloadSummary = true;
        } else if (to.query.tagSlug !== this.tagSlug) {
          this.tagId = void 0;
          this.tagSlug = to.query.tagSlug;
          reloadSummary = true;
        }

        if (to.query.page !== this.page) {
          this.page = Math.max(Number(to.query.page), 1) || null;
        }

        if (reloadSummary) {
          this.reloadReceiptsAndSummary();
        } else {
          this.reloadReceipts();
        }
      }
    },

    created() {
      this.reloadAll();
      this
        .$on('receipt-failed-to-delete', (err) => this.showFailMessage(err.message))
        .$on('receipt-deleted', (receipt) => {
          this.showSuccessMessage(`Skasowano paragon #${receipt.id}`);

          const groupIndex = this.receipts.findIndex(({ date }) => date === receipt.date);

          if (groupIndex >= 0) {
            const group = this.receipts[groupIndex];
            const index = group.receipts.findIndex(({ id }) => id === receipt.id);

            if (index >= 0) {
              group.receipts.splice(index, 1);
            }

            if (group.receipts.length === 0) {
              this.receipts.splice(groupIndex, 1);
            }
          }
        });
    },

    computed: {
      period() {
        return toPeriod(this);
      },

      isGrouped() {
        return !this.order || this.order.indexOf('date') === 0;
      }
    },

    methods: {
      reloadAll() {
        this.loading = true;
        Promise.all([
          this.updateShopsList(),
          this.updateSummary(),
          this.updateReceipts()
        ])
          .catch((err) => this.showFailMessage(err.message))
          .then(() => (this.loading = false));
      },

      reloadReceiptsAndSummary() {
        this.loading = true;
        Promise.all([
          this.updateSummary(),
          this.updateReceipts()
        ])
          .catch((err) => this.showFailMessage(err.message))
          .then(() => (this.loading = false));
      },

      reloadReceipts() {
        this.loadingReceipts = true;
        this.updateReceipts()
          .catch((err) => this.showFailMessage(err.message))
          .then(() => (this.loadingReceipts = false));
      },

      updateSummary() {
        return this.$api.getReceiptsSummary({
          q: this.query,
          shopId: toNumber(this.shopId),
          tagId: toNumber(this.tagId),
          tagSlug: this.tagSlug,
        })
          .then((summary) => (this.summary = summary));
      },

      updateReceipts() {
        return this.$api.getReceipts({
          period: this.period,
          order: this.order || 'date DESC',
          q: this.query,
          shopId: toNumber(this.shopId),
          tagId: toNumber(this.tagId),
          tagSlug: this.tagSlug,
          offset: this.page ? this.limit * (this.page - 1) : 0
        })
          .then(({ meta, items }) => {
            this.receipts = parseReceipts(items);
            this.receiptsMeta = meta;
          });
      },

      searchByShop(shop) {
        if (shop) {
          this.goTo({
            q: void 0,
            shopId: toNumber(shop.id)
          });
        }
      },

      searchByQuery(query) {
        if (this.$route.query.q === query) {
          return;
        }

        this.goTo({
          q: query,
          shopId: void 0
        });
      },

      clearSearch() {
        this.goTo({
          q: void 0,
          shopId: void 0
        });
      },

      goTo(params) {
        this.$router.push({
          query: Object.assign({
            period: this.period || void 0
          }, params)
        });
      },

      goToReceipt(receipt) {
        this.$router.push(`/receipts/${receipt.id}`);
      },

      quickAddReceipt(receipt) {
        this.showModal(Modals.RECEIPT_FORM, {
          shopId: receipt.shopId,
          date: receipt.date,
          tags: receipt.tags
        })
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano paragon w ${result.shop.name} na ${result.total} zł.`, `/receipts/${result.id}`);
              this.updateSummary();
              this.updateReceipts();
            }
          })
      },

      editShop() {
        this.showModal(Modals.SHOP_FORM, this.shopId)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Edytowano sklep ${result.name}`);
              this.updateShopsList();
            }
          })
      },

      addShopReceipt() {
        this.showModal(Modals.RECEIPT_FORM, {
          shopId: this.shopId
        })
          .then((receipt) => {
            if (receipt) {
              this.showSuccessMessage(`Dodano paragon w ${receipt.shop.name} na ${receipt.total} zł.`, `/receipts/${receipt.id}`);
              this.updateShopsList();
            }
          })
      }
    }
  };

  function parseQuery(query) {
    return {
      shopId: toNumber(query.shopId),
      query: query.q,
      tagId: query.tagId,
      tagSlug: query.tagSlug
    };
  }

  function toNumber(string) {
    if (!string) {
      return undefined;
    }

    const result = Number(string);

    return isNaN(result) ? undefined : result;
  }
</script>

<style lang="scss" src="./ReceiptsList.scss"/>
