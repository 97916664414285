<template>
  <div class="income-form">
    <section>
      <label>
        <span>Dawca</span>
        <PayerAutocomplete
          ref="payerInput"
          :payer="payer"
          @select="onSelect"
          @select-new="onUnknownPayer"
        />
      </label>

      <label>
        <span>Termin</span>
        <DatePicker :start-date="date" @select="setDate"/>
      </label>

      <label>
        <span>Kwota</span>
        <AmountInput :amount.sync="income.income"/>
      </label>

      <label>
        <span>Opis</span>
        <textarea placeholder="np. wynagrodzenie" v-auto-select v-model="income.description"/>
      </label>
    </section>
  </div>
</template>

<script>
  import moment from 'moment';
  import DatePicker from '@components/DatePicker/DatePicker';
  import AmountInput from '@components/Inputs/AmountInput';
  import PayerAutocomplete from '@components/Income/Payers/PayerAutocomplete';
  import { Modals } from '@components/consts';

  export default {
    components: {
      PayerAutocomplete,
      AmountInput,
      DatePicker
    },

    inject: [
      'showModal'
    ],

    props: {
      income: {
        default() {
          return {
            id: null,
            payerId: null,
            name: '',
            date: new Date(),
            description: '',
            income: null
          };
        }
      }
    },

    computed: {
      date: {
        set(val) {
          this.income.date = val.toDate();
        },

        get() {
          return moment.utc(this.income.date);
        }
      },

      payer: {
        get() {
          return {
            id: this.income.payerId,
            name: this.income.name
          };
        },

        set(payer) {
          this.income.name = payer.name;
          this.income.payerId = payer.id;
        }
      }
    },

    methods: {
      setDate(date) {
        this.date = date;
      },

      onUnknownPayer(name, event) {
        event.preventDefault();

        this.showModal(Modals.PAYER_FORM, {
          name
        })
          .then((payer) => {
            if (payer) {
              this.onSelect(payer);
            } else {
              this.$refs.payerInput.focus();
            }
          })
      },

      onSelect(payer) {
        if (!payer || this.income.payerId === payer.id) {
          return;
        }

        this.income.name = payer.name;
        this.income.description = payer.defaultDescription;

        if (!this.income.income) {
          this.income.income = payer.defaultIncome;
        }
      }
    }
  };
</script>
