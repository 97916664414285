<template>
    <li class="auto-complete-item">{{ item.label }}</li>
</template>

<script>
  export default {
    name: 'auto-complete-item',
    props: {
      item: Object
    }
  };
</script>
