<template>
  <transition name="modal" @after-enter="onReady">
    <div class="modal-mask"
         @keydown.esc.prevent.stop="$emit('close')">
      <div class="modal-container">
        <form @submit.prevent.stop="$emit('submit')">

        <h4 class="modal-header">
          <slot name="header">Modal</slot>
        </h4>

        <div ref="body" class="modal-body" @keydown.tab="navigateToFooter($event)">
          <slot name="body">
            Modal content
          </slot>
        </div>

        <div class="modal-footer" @keydown.left.right.tab.prevent="navigate($event)" ref="footer">
          <slot name="footer">
            <button @click="$emit('close')" v-focus>OK</button>
          </slot>
        </div>

        </form>
      </div>
    </div>
  </transition>
</template>

<script>
  import { KeyCodes } from '@/consts';

  const ANY_INPUT = 'input, textarea, button';
  const ANY_BUTTON = 'input, button';
  const PRIMARY_BUTTON = '.primary, input[type=submit], input'

  export default {
    data() {
      return {
        toFocus: null
      };
    },

    methods: {
      navigateToFooter(event) {
        const inputs = Array.from(this.$refs.body.querySelectorAll(ANY_INPUT));
        const index = inputs.indexOf(event.target);

        if (index === inputs.length - 1) {
          event.preventDefault();
          this.focusFooter();
        }
      },

      navigate(event) {
        if (this.inputs.length <= 1) {
          return;
        }

        const focused = document.activeElement;
        const index = this.inputs.indexOf(focused);
        const direction = (event.keyCode === KeyCodes.RIGHT || event.keyCode === KeyCodes.TAB) ? 1 : -1;

        if (index === -1) {
          return;
        }

        const newIndex = (index + direction + this.inputs.length) % this.inputs.length;

        this.inputs[newIndex].focus();
      },

      focus() {
        (this.$refs.body.querySelector(ANY_INPUT) || this.$refs.footer.querySelector(PRIMARY_BUTTON))?.focus();
      },

      focusFooter() {
        this.$refs.footer.querySelector(PRIMARY_BUTTON)?.focus();
      },

      onReady() {
        if (this.toFocus) {
          this.toFocus.focus();
        }
      }
    },
    mounted() {
      const focused = document.activeElement;

      if (!this.$el.contains(focused)) {
        this.toFocus = this.$refs.body.querySelector(ANY_INPUT) || this.$refs.footer.querySelector(PRIMARY_BUTTON);
      } else {
        this.toFocus = focused;
        focused.blur();
      }

      this.inputs = Array.from(this.$refs.footer.querySelectorAll(ANY_BUTTON));

      document.documentElement.style.overflow = 'hidden';
    },
    destroyed() {
      document.documentElement.style.overflow = 'auto';
    }
  };
</script>

<style lang="scss" src="./Modal.scss" />
