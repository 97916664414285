<template>
  <auto-complete ref="input"
                 :selected-item="selected"
                 :items="items"
                 :allow-not-found="allowNotFound"
                 :placeholder="placeholder"
                 :select-first="selectFirst"
                 @select="$emit('select', arguments[0], arguments[1])"
                 @select-new="$emit('select-new', arguments[0], arguments[1])"
                 @select-empty="$emit('select-empty', arguments[0])"
                 @not-found="$emit('not-found', arguments[0], arguments[1])">
  </auto-complete>
</template>

<script>
  import AutoComplete from '../AutoComplete/AutoComplete';
  import { sortBy } from 'lodash';

  function toAutoCompleteItem(recipient) {
    return {
      data: recipient,
      order: recipient.active,
      label: recipient.name
    };
  }

  export default {
    name: 'recipient-autocomplete',

    inject: ['$api'],

    components: {
      AutoComplete
    },

    props: {
      selectFirst: {
        default: false
      },
      allowNotFound: {
        default: true
      },
      placeholder: {
        default: 'np. Inea'
      },
      recipient: {
        default: null
      }
    },

    data() {
      return {
        recipients: []
      };
    },

    computed: {
      items() {
        return this.recipients.map(toAutoCompleteItem);
      },

      selected() {
        if (!this.recipient) {
          return null;
        }

        if (this.recipient.id) {
          const recipient = this.recipients.find((recipient) => recipient.id === this.recipient.id);

          if (recipient) {
            return toAutoCompleteItem(recipient);
          }
        }

        return toAutoCompleteItem(this.recipient);
      }
    },

    created() {
      this.$api.getRecipientsList()
        .then((recipients) => {
          this.recipients = sortBy(recipients, ['active']);
        });
    }
  };
</script>
