<template>
  <div class="tag-input" :class="{ active }" @keydown="onKey($event)">
    <input class="tag-input__input"
           ref="input"
           type="text"
           :placeholder="placeholder"
           v-model="value"
           @focus="activate"
           @blur="deactivate"
    />
    <ul class="tag-input__list" ref="list" v-show="active && suggestedTags.length">
      <li class="tag-input__list-item"
          v-for="(tag, index) in suggestedTags"
          :key="tag.id"
          :class="{ active: index === selectedIndex }"
          @click="select(tag)"
          @mousedown.prevent
      >
        {{ tag.name }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" src="./TagInput.scss"/>

<script>
  import slugize from '@utils/slug';
  import { KeyCodes } from '@/consts';

  export default {
    name: 'tag-input',
    props: {
      tags: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: 'Tags'
      }
    },

    inject: [
      '$api'
    ],

    data() {
      return {
        active: false,
        selectedIndex: -1,
        availableTags: []
      };
    },

    created() {
      this.$api.getTagsList()
        .then((tags) => (this.availableTags = tags.map((tag) => ({
          ...tag,
          slug: slugize(tag.name)
        }))));
    },

    mounted() {
      this.updateDimensions();
    },

    watch: {
      suggestedTags() {
        if (this.selectedIndex > this.suggestedTags.length) {
          this.selectedIndex = 0;
        }
      }
    },

    computed: {
      suggestedTags() {
        const isAddingNewTag = !this.value?.length || /\s/.test(this.value.slice(-1));
        const words = this.value.trim().split(/\s+/);

        const usedTagsMap = words.reduce((map, word) => {
          map[slugize(word)] = true;

          return map;
        }, {});

        const remainingTags = this.availableTags.filter((tag) => !usedTagsMap[tag.slug]);

        if (isAddingNewTag) {
          return remainingTags;
        }

        const lastWordSlug = slugize(words[words.length - 1]);

        return remainingTags.filter((tag) => tag.slug.indexOf(lastWordSlug) >= 0);
      },

      value: {
        get() {
          return this.tags;
        },

        set(value) {
          this.$emit('change', value);
        }
      }
    },

    methods: {
      onKey(event) {
        let handled = false;

        switch (event.keyCode) {
          case KeyCodes.DOWN:
            this.moveSelected(1);
            handled = true;
            break;
          case KeyCodes.UP:
            this.moveSelected(-1);
            handled = true;
            break;
          case KeyCodes.ENTER:
          case KeyCodes.TAB:
            if (this.active && this.selectedIndex >= 0) {
              this.select(this.suggestedTags[this.selectedIndex], event);
              handled = true;
            }
            break;
          case KeyCodes.ESC:
            if (!this.active) {
              return;
            }

            this.deactivate();
            handled = true;
            break;
        }

        if (handled) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          this.activate();
        }
      },

      getInputElement() {
        return this.$refs.input;
      },

      getListElement() {
        return this.$refs.list
      },

      getOutletElement() {
        return this.$el.closest('.modal-body') || document.body;
      },

      updateDimensions() {
        const input = this.getInputElement();
        const list = this.getListElement();
        const outlet = this.getOutletElement();

        if (!list || !input) {
          return;
        }

        const inputRect = input.getBoundingClientRect();
        const outletRect = outlet.getBoundingClientRect();

        const heightAbove = inputRect.top - outletRect.top;
        const heightBelow = outletRect.bottom - inputRect.bottom;

        const shift = `${inputRect.height + 2}px`;

        list.style.bottom = heightAbove > heightBelow ? shift : '';
        list.style.top = heightAbove > heightBelow ? '' : shift;
        list.style.maxHeight = `${Math.max(heightAbove, heightBelow) - 20}px`;
      },

      adjustFocus() {
        const list = this.getListElement();

        const {
          clientHeight: wrapperHeight,
          scrollTop: wrapperTop
        } = list;

        const {
          clientHeight: itemHeight,
          offsetTop: itemTop
        } = list.childNodes[this.selectedIndex];

        if (itemTop + itemHeight > wrapperHeight + wrapperTop) {
          list.scrollTop = itemTop + itemHeight - wrapperHeight;
        } else if (itemTop < wrapperTop) {
          list.scrollTop = itemTop;
        }
      },

      moveSelected(diff) {
        const count = this.suggestedTags.length;

        if (!count) {
          return;
        }

        this.selectedIndex = (this.selectedIndex + count + diff) % count;
        this.adjustFocus();
      },

      select(tag) {
        if (!tag) {
          return;
        }

        const words = this.value.split(/\s+/);
        const lastWord = words.length && words[words.length - 1]

        if (lastWord && slugize(tag.name).indexOf(slugize(lastWord)) >= 0) {
          words.splice(-1, 1, tag.name);
        } else {
          words.push(tag.name);
        }

        this.value = words.filter(Boolean).join(' ').trim() + ' ';
      },

      activate() {
        if (this.active) {
          return;
        }

        this.active = true;
        this.updateDimensions();
      },

      deactivate() {
        if (!this.active) {
          return;
        }

        this.active = false;
        this.selectedIndex = -1;
      }
    }
  };
</script>
