<template>
  <Page :loading="loading">
    <h1 slot="header">Rachunki</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
      <button type="button" @click="addBill">Nowy rachunek</button>
      <button type="button" @click="addRecipient">Nowy odbiorca</button>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/bills/recipients">Odbiorcy</RelatedItem>
      </Related>

      <SummaryFilter
        :summary-data="summary"
        :period="period"
        :month="month"
        :year="year"
        :date="date"
      />

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="deadline" as-default="desc">Termin płatności</Sorter>
        <Sorter by="created">Data utworzenia</Sorter>
        <Sorter by="cost">Kwota</Sorter>
        <Sorter by="name">Nazwa</Sorter>
      </div>
    </template>

    <template slot="body" v-if="loadingItems">
      <Loader/>
    </template>

    <template slot="body" v-else>
      <List :items="items" :meta="meta" :group-by="groupBy">
        <template slot="group-header" slot-scope="{ item }">
          <h4 class="group-by-date">
            <router-link :to="`/bills?period=${getGroupName(item)}`">{{ getGroupDescription(item) }}</router-link>
          </h4>
        </template>
        <template slot="actions" slot-scope="{ selected }">
          <BulkActionItem @click.native="bulkDuplicate(selected)" v-if="selected.length">Kopiuj</BulkActionItem>
        </template>
        <BillItem slot-scope="{ item }" :bill="item" @remove="load" @invalidate="load"/>
      </List>
    </template>
  </Page>
</template>

<script>
  import SummaryFilter from '@components/Summary/SummaryFilter';
  import BulkActionItem from '@components/List/BulkActionItem';
  import Sorter from '@components/Sorter/Sorter';
  import List from '@components/List/List';
  import Loader from '@components/Loader';
  import Page from '@components/Page';

  import BillItem from './BillItem';

  import { ListMixin } from '@mixins';

  import moment from 'moment';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';
  import { getDeadlineInThisMonth } from '@components/Bills/utils';

  export default {
    inject: [
      '$api',
      'showModal'
    ],

    mixins: [
      ListMixin
    ],

    components: {
      RelatedItem,
      Related,
      SummaryFilter,
      BulkActionItem,
      BillItem,
      Page,
      List,
      Loader,
      Sorter
    },

    computed: {
      groupBy() {
        return !this.order || this.order.startsWith('deadline') ? this.getGroupName : null;
      }
    },

    methods: {
      addBill() {
        this.showModal(Modals.BILL_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano rachunek ${result.name}`);
              this.load();
            }
          });
      },

      addRecipient() {
        this.showModal(Modals.RECIPIENT_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano odbiorcę ${result.name}`);
            }
          });
      },

      async bulkDuplicate(selected) {
        const result = await this.showModal(Modals.CONFIRM, {
          body: `Na pewno chcesz skopiować ${selected.length} rachunków?`
        });

        if (!result) {
          return;
        }

        const newBills = selected.map((bill) => ({
          ...bill,
          id: null,
          status: false,
          payDate: null,
          deadline: getDeadlineInThisMonth(bill)
        }));

        try {
          const results = await Promise.all(newBills.map((bill) => this.$api.saveBill(bill)));

          this.showSuccessMessage(`Dodano ${results.length} rachunków`);
          this.load();
        } catch (err) {
          this.showFailMessage(err.message);
        }
      },

      fetch(query) {
        if (!query.order) {
          query.order = 'deadline desc';
        }

        return this.$api.getBills(query);
      },

      fetchSummary(query) {
        return this.$api.getBillsSummary(query);
      },

      getGroupName(bill) {
        return bill.deadline.substring(0, 7);
      },

      getGroupDescription(bill) {
        return moment.utc(bill.deadline).format('MMMM YYYY');
      }
    }
  };
</script>
