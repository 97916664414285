export function toQueryParams(params) {
  if (!params) {
    return '';
  }

  const keys = Object.keys(params);

  if (!keys.length) {
    return '';
  }

  const query = keys
    .filter((key) => typeof params[key] !== 'undefined' && params[key] !== null)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);

  return `?${query.join('&')}`;
}
