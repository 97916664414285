<template>
  <section class="main">
    <slot name="header">
      <h1>Tytuł</h1>
    </slot>

    <div v-if="!loading" class="actions">
      <slot name="actions"/>
    </div>

    <loader v-if="loading"/>

    <form v-else @submit.prevent="$emit('submit')">
      <div class="content-wrapper">

        <div class="aside">
          <slot name="aside">
            Aside content
          </slot>
        </div>

        <div class="content">
          <slot name="body">
            Main body
          </slot>
        </div>

      </div>
    </form>
  </section>
</template>

<script>
  import Loader from './Loader';

  export default {
    name: 'page',
    props: ['loading'],
    components: {
      Loader
    }
  };
</script>
