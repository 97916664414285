<template>
  <ListItemWithDetails class="recipient-item" :class="{ active: recipient.active }">
    <template slot="summary">
      <BillSummary :bill="recipient"/>
    </template>

    <template slot="details">
      <BillDetails :bill="recipient"/>
    </template>

    <template slot="actions">
      <NewItemAction @click.native="addBill" title="Dodaj rachunek"/>
      <ToggleItemAction @click.native="toggleRecipient" :status="recipient.active"/>
      <EditItemAction @click.native="editRecipient"/>
      <RemoveItemAction @click.native="removeRecipient"/>
    </template>
  </ListItemWithDetails>
</template>

<script>
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import BillDetails from '@components/Bills/BillDetails';
  import BillSummary from '@components/Bills/BillSummary';
  import ListItemWithDetails from '@components/List/ListItemWithDetails';
  import NewItemAction from '@components/List/NewItemAction';
  import ToggleItemAction from '@components/List/ToggleItemAction';
  import { Modals } from '@components/consts';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    components: {
      ToggleItemAction,
      NewItemAction,
      ListItemWithDetails,
      RemoveItemAction,
      EditItemAction,
      BillDetails,
      BillSummary
    },

    props: {
      recipient: {
        type: Object
      }
    },

    data() {
      return {
        bill: null
      };
    },

    methods: {
      toggleRecipient() {
        return this.$api.saveRecipient({
          id: this.recipient.id,
          active: !this.recipient.active
        })
          .then((recipient) => {
            this.recipient.active = recipient.active;
            this.$emit('toggled', recipient);
          }, (err) => this.$emit('error', err));
      },

      addBill() {
        this.showModal(Modals.BILL_FORM, { recipientId: this.recipient.id })
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano rachunek ${this.recipient.name}`);
            }
          });
      },

      editRecipient() {
        this.showModal(Modals.RECIPIENT_FORM, this.recipient)
          .then((result) => {
            if (result) {
              Object.assign(this.recipient, result);
              this.showSuccessMessage(`Zaktualizowano odbiorcę ${this.recipient.name}`);
              this.$emit('update', result);
            }
          });
      },

      removeRecipient() {
        this.showModal(Modals.RECIPIENT_DELETE_CONFIRM, this.recipient)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Usunięto odbiorcę ${this.recipient.name}`);
              this.$emit('remove', result);
            }
          });
      }
    }
  };
</script>

<style scoped lang="scss">
  .recipient-item:not(.active) .expense-entry {
    color: #c2c2c2;
  }
</style>
