import moment from 'moment';

const PERIOD = /^([0-9]{4})(-([0-1]?[0-9])(-([0-3]?[0-9]))?)?$/;
const MONTHS = moment.months('M');
const ALL_PERIODS = 'Cały okres';

export function padWithDash(number) {
  return number < 10 ? `-0${number}` : `-${number}`;
}

export function parsePeriodQuery({ period }) {
  if (!period || !PERIOD.test(period)) {
    return {
      year: null,
      month: null,
      date: null
    };
  }

  return {
    year: RegExp.$1 || null,
    month: RegExp.$3 ? Number(RegExp.$3) - 1 : null,
    date: RegExp.$5 || null
  };
}

export function toPeriod({ year, month, date }) {
  let period;

  if (year) {
    period = year;

    if (month || month === 0) {
      period += padWithDash(Number(month) + 1);

      if (date) {
        period += padWithDash(Number(date));
      }
    }
  }

  return period;
}

export function toPeriodName({ year, month, date }) {
  if (year) {
    if (month) {
      if (date) {
        return moment.utc([year, month, date]).format('L');
      }

      return `${MONTHS[month]} ${year}`;
    }

    return year;
  }

  return ALL_PERIODS;
}

function createSummaryItem({ label, year, month, date }) {
  return {
    label,
    total: 0,
    totalMax: 0,
    totalShare: 1,
    totalVsMax: 1,
    count: 0,
    countMax: 0,
    countShare: 1,
    countVsMax: 1,
    map: {},
    children: [],
    period: toPeriod({
      year,
      month,
      date
    })
  };
}

function getSummaryItem(store, key, options) {
  let item = store.map[key];

  if (!item) {
    item = createSummaryItem(options);
    store.map[key] = item;
    store.children.push(item);
  }

  return item;
}

function decorateWithMax(summaryItem) {
  summaryItem.countMax = Math.max(...summaryItem.children.map((item) => item.count));
  summaryItem.totalMax = Math.max(...summaryItem.children.map((item) => item.total));
  summaryItem.children.forEach(decorateWithMax);
}

function decorateWithShare(summaryItem) {
  summaryItem.children.forEach((item) => {
    item.countShare = item.count / summaryItem.count;
    item.countVsMax = item.count / summaryItem.countMax;
    item.totalShare = item.total / summaryItem.total;
    item.totalVsMax = item.total / summaryItem.totalMax;
    decorateWithShare(item);
  });
}

export function parseSummary(data) {
  const summary = createSummaryItem(ALL_PERIODS);

  if (!data) {
    return summary;
  }

  data.forEach((item) => {
    const momentDate = moment.utc(item.date);
    const year = momentDate.year();
    const month = momentDate.month();
    const date = momentDate.date();

    if (isNaN(year)) {
      return;
    }

    const yearSummary = getSummaryItem(summary, year, {
      label: year,
      year
    });

    const monthSummary = getSummaryItem(yearSummary, month, {
      label: MONTHS[month],
      year,
      month
    });

    const daySummary = getSummaryItem(monthSummary, date, {
      label: date,
      year,
      month,
      date
    });

    summary.total += item.total;
    summary.count += item.count;
    yearSummary.total += item.total;
    yearSummary.count += item.count;
    monthSummary.total += item.total;
    monthSummary.count += item.count;
    daySummary.total = item.total;
    daySummary.count = item.count;
  });

  decorateWithMax(summary);
  decorateWithShare(summary);

  return summary;
}
