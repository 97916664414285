<template>
  <SubmitModal ref="modal" :submit="confirm">
    <template slot="cancel">Nie</template>
    <template slot="header">Paragon już istnieje!</template>
    <template slot="submit">Tak</template>

    <div slot="body" slot-scope="{ item }">
      <p>Paragon ze sklepu {{ item.shop.name }} na kwotę {{ item.total }} zł z dnia {{ item.date.format('DD.MM.YYYY') }} już istnieje.</p>
      <p>Czy na pewno chcesz dodać nowy paragon?</p>
    </div>
  </SubmitModal>
</template>

<script>
  import SubmitModal from './SubmitModal';
  import moment from 'moment';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
    },

    methods: {
      async show(props) {
        const shop = props.shop || await this.$api.getShop(props.shopId);

        return this.$refs.modal.show({
          date: moment.utc(props.date),
          total: props.total ?? 0,
          shop
        });
      },

      confirm() {
        return true;
      }
    }
  };
</script>
