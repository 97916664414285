<template>
  <modal @submit="login()">
    <template slot="header">Logowanie</template>

    <div class="body" slot="body">
      <input type="text" v-focus v-model="username" name="username" placeholder="Login">
      <input type="password" v-model="password" name="password" placeholder="Hasło"/>

      <p class="error-message" v-if="error">{{ error }}</p>
    </div>

    <div class="footer" slot="footer">
      <spinning-button ref="button" :loading="loading">Zaloguj</spinning-button>
    </div>
  </modal>
</template>

<script>
  import Modal from '../components/Modal/Modal';
  import SpinningButton from '../components/SpinningButton/SpinningButton';

  export default {
    name: 'login-form',

    inject: [
      '$api'
    ],

    components: {
      SpinningButton,
      Modal
    },

    data() {
      return {
        username: '',
        password: '',
        loading: false,
        error: null
      };
    },

    methods: {
      login() {
        if (!this.username || !this.password) {
          this.$refs.button.shake();

          return;
        }

        const credentials = {
          username: this.username,
          password: this.password
        };

        this.loading = true;

        // eslint-disable-next-line consistent-return
        return this.$api.login(credentials)
          .then(() => {
            const { redirectQuery } = this.$route.query;
            const target = (redirectQuery && decodeURIComponent(redirectQuery)) || '/dashboard';

            this.$router.push(target);
          }, (err) => {
            this.$refs.button.shake();
            this.error = err.message;
            this.loading = false;
          });
      }
    }
  };
</script>

<style lang="scss">
  @import "../styles/variables.scss";

  .error-message {
    font-size: 0.8em;
    color: $errorColor;
    text-align: center;
    margin: 10px 0 0;
  }
</style>
