<template>
  <ListItem class="income-item">
    <template slot="summary">
      <IncomeSummary :income="income"/>
    </template>

    <template slot="actions">
      <EditItemAction @click.native="editIncome" v-if="showEdit"/>
      <RemoveItemAction @click.native="removeIncome" v-if="showDestroy"/>
    </template>
  </ListItem>
</template>

<script>
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import ListItem from '@components/List/ListItem';
  import IncomeSummary from './IncomeSummary';
  import { Modals } from '@components/consts';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    components: {
      IncomeSummary,
      ListItem,
      RemoveItemAction,
      EditItemAction
    },

    props: {
      income: {
        type: Object
      },
      showDestroy: {
        type: Boolean,
        default: true
      },
      showEdit: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      editIncome() {
        this.showModal(Modals.INCOME_FORM, this.income)
          .then((result) => {
            if (result) {
              Object.assign(this.income, result);
              this.showSuccessMessage(`Zaktualizowano wpływ od ${this.income.name}`);
              this.$emit('update', result);
            }
          });
      },

      removeIncome() {
        this.showModal(Modals.INCOME_DELETE_CONFIRM, this.income)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Usunięto wpływ od ${this.income.name}`);
              this.$emit('remove', result);
            }
          });
      }
    }
  };
</script>
