export function getAddress(shop) {
  if (shop.address && shop.city) {
    return `${shop.address}, ${shop.city}`;
  }

  return shop.address || shop.city || '';
}

export function getShopLabel(shop) {
  const address = getAddress(shop);

  return address ? `${shop.name}, ${address}` : shop.name;
}
