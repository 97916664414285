<template>
  <div id="app">
    <navigation v-if="authenticated"/>
    <messages v-if="authenticated"/>
    <server-unavailable v-if="!available"/>
    <loader v-if="loading"/>
    <router-view v-if="!loading && available"/>
    <modals ref="modals"/>
  </div>
</template>

<script>
  import Navigation from '@components/Navigation/Navigation';
  import Loader from '@components/Loader';
  import Messages from '@components/Messages/Messages';
  import ServerUnavailable from '@components/Modal/ServerUnavailable';
  import Modals from '@components/Modals';

  export default {
    name: 'app',

    inject: [
      '$api'
    ],

    provide() {
      return {
        showModal: this.showModal,
        showSuccessMessage: this.showSuccessMessage,
        showFailMessage: this.showFailMessage
      };
    },

    components: {
      Modals,
      ServerUnavailable,
      Loader,
      Messages,
      Navigation
    },

    data() {
      return {
        loading: true,
        authenticated: this.$api.isAuthenticated,
        available: this.$api.serverAvailable
      };
    },

    created() {
      const hideLoader = () => (this.loading = false);

      this.$api.authPromise
        .then(hideLoader, hideLoader);

      this.$api
        .on('server-status', (newValue) => (this.available = newValue))
        .on('logged-in', () => (this.authenticated = true))
        .on('logged-out', () => {
          this.authenticated = false;

          if (this.$route.path !== '/') {
            this.$router.push(`/?redirect=${encodeURIComponent(this.$route.fullPath)}`);
          }
        });
    },

    methods: {
      showModal(...args) {
        return this.$refs.modals.showModal(...args);
      },

      showSuccessMessage(content, link) {
        this.$root.$emit('message', {
          type: 'success',
          link,
          content
        });
      },

      showFailMessage(content, link) {
        this.$root.$emit('message', {
          type: 'failure',
          link,
          content
        });
      }
    }
  };
</script>

<style lang="scss">
  @import "styles/layout.scss";
</style>
