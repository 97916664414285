<template>
  <ListItem class="payer-item">
    <template slot="summary">
      <PayerSummary :payer="payer"/>
    </template>

    <template slot="actions">
      <NewItemAction @click.native="addIncome" title="Dodaj wpływ"/>
      <EditItemAction @click.native="editRecipient"/>
      <RemoveItemAction @click.native="removeRecipient"/>
    </template>
  </ListItem>
</template>

<script>
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import NewItemAction from '@components/List/NewItemAction';
  import ListItem from '@components/List/ListItem';
  import PayerSummary from '@components/Income/Payers/PayerSummary';
  import { Modals } from '@components/consts';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    components: {
      PayerSummary,
      ListItem,
      NewItemAction,
      RemoveItemAction,
      EditItemAction,
    },

    props: {
      payer: Object
    },

    methods: {
      addIncome() {
        this.showModal(Modals.INCOME_FORM, {
          payerId: this.payer.id
        })
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano wpływ od ${result.name}`);
            }
          })
      },

      editRecipient() {
        this.showModal(Modals.PAYER_FORM, this.payer)
          .then((result) => {
            if (result) {
              Object.assign(this.payer, result);
              this.showSuccessMessage(`Zaktualizowano dawcę ${this.payer.name}`);
              this.$emit('update', result);
            }
          });
      },

      removeRecipient() {
        this.showModal(Modals.PAYER_DELETE_CONFIRM, this.payer)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Usunięto dawcę ${this.payer.name}`);
              this.$emit('remove', result);
            }
          });
      }
    }
  };
</script>
