export const Modals = {
  // generic
  CONFIRM: 'confirm',

  // shop
  SHOP_DELETE_CONFIRM: 'shopDeleteConfirm',
  SHOP_FORM: 'shopForm',

  // income
  INCOME_DELETE_CONFIRM: 'incomeDeleteConfirm',
  INCOME_FORM: 'incomeForm',

  // payer
  PAYER_DELETE_CONFIRM: 'payerDeleteConfirm',
  PAYER_FORM: 'payerForm',

  // bills
  BILL_DELETE_CONFIRM: 'billDeleteConfirm',
  BILL_FORM: 'billForm',
  REPEAT_BILL: 'repeatBill',

  // recipients
  RECIPIENT_DELETE_CONFIRM: 'recipientDeleteConfirm',
  RECIPIENT_FORM: 'recipientForm',

  // tags
  TAG_DELETE_CONFIRM: 'tagDeleteConfirm',
  TAG_FORM: 'tagForm',

  // product
  PRODUCT_DELETE_CONFIRM: 'productDeleteConfirm',
  PRODUCT_FORM: 'productForm',

  // receipts
  RECEIPT_DELETE_CONFIRM: 'receiptDeleteConfirm',
  RECEIPT_FORM: 'receiptForm',
  RECEIPT_ADD_CONFIRM: 'receiptAddConfirm'
};
