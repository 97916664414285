<template>
  <button
    type="submit"
    :class="{ loading: loading, shaking: shaking }"
    :disabled="loading"
  >
    <template v-if="loading">
      <i class="fa fa-spin fa-spinner"></i>
    </template>
    <template v-else>
      <slot>Save</slot>
    </template>
  </button>
</template>

<script>
  const SHAKE_TIME = 1000;

  export default {
    name: 'spinning-button',
    props: {
      loading: Boolean
    },

    data() {
      return {
        shaking: false
      };
    },

    methods: {
      shake() {
        this.shaking = true;

        setTimeout(() => (this.shaking = false), SHAKE_TIME);
      }
    }
  };
</script>

<style>
  .shaking {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
