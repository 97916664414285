<template>
  <div class="summary-filter">
    <h3>Podsumowanie</h3>

    <div class="summary-filter__scope">
      <router-link :to="summaryLink"><i class="fa fa-angle-left" v-if="year"></i> {{ summaryPath }}</router-link>
    </div>

    <ul :class="{ packed: packable && month !== null }">
      <SummaryItem v-for="item in summary"
                   :key="item.period"
                   :item="item"
                   :class="{ active: period === item.period }"
                   :formatHint="formatCount"
      />
    </ul>

    <div class="summary-global" v-if="summaryLevel">
      <p>Razem: <span class="total">{{ formatTotal(summaryLevel.total) }}</span></p>
      <p>Pozycji: <span class="count">{{ formatCount(summaryLevel.count) }}</span></p>
    </div>
  </div>
</template>

<script>
  import { toPeriod, toPeriodName, parseSummary } from '@utils/periods';
  import QueryMixin from '@mixins/QueryMixin';
  import SummaryItem from './SummaryItem';

  export default {
    components: {
      SummaryItem
    },

    mixins: [
      QueryMixin
    ],

    props: {
      summaryData: {
        default: null
      },

      period: {
        default: null
      },
      month: {
        default: null
      },
      date: {
        default: null
      },
      year: {
        default: null
      },

      packable: {
        default: false
      },

      formatCount: {
        default() {
          return (count) => count;
        }
      },
      formatTotal: {
        default() {
          return (total) => `${this.$options.filters.currency(total)} zł`;
        }
      }
    },

    data() {
      return {
        parsedSummary: parseSummary(this.summaryData)
      };
    },

    watch: {
      summaryData(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.parsedSummary = parseSummary(newVal);
        }
      }
    },

    computed: {
      summaryPath() {
        return toPeriodName(this);
      },

      summaryLink() {
        if (!this.year) {
          return this.toQueryLink({});
        }

        return this.toQueryLink({
          period: toPeriod({
            year: this.month || this.month === 0 ? this.year : null,
            month: this.date ? this.month : null
          })
        });
      },

      summaryLevel() {
        const empty = {
          total: 0,
          count: 0
        };

        if (!this.parsedSummary) {
          return null;
        }

        if (this.year) {
          const yearSummary = this.parsedSummary.map[this.year];

          if (!yearSummary) {
            return empty;
          }

          if (this.month || this.month === 0) {
            const monthSummary = yearSummary.map[Number(this.month)];

            if (!monthSummary) {
              return empty;
            }

            if (this.date) {
              return monthSummary.map[Number(this.date)] || empty;
            }

            return monthSummary;
          }

          return yearSummary;
        }

        return this.parsedSummary;
      },

      summary() {
        if (!this.parsedSummary || !this.parsedSummary.children || !this.parsedSummary.children.length) {
          return [];
        }

        if (this.year) {
          const yearSummary = this.parsedSummary.map[this.year];

          if (!yearSummary) {
            return [];
          }

          if (this.month || this.month === 0) {
            const monthSummary = yearSummary.map[this.month];

            if (!monthSummary) {
              return [];
            }

            return monthSummary.children;
          }

          return yearSummary.children;
        }

        return this.parsedSummary.children;
      }
    }
  };
</script>

<style lang="scss" scoped src="./SummaryFilter.scss"></style>
