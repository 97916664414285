<template>
  <router-link :to="link" class="sorter" :class="{ active, ascending, descending }">
    <i class="fa" :class="{ 'fa-sort-amount-asc': ascending, 'fa-sort-amount-desc': descending }"></i><slot>Sortuj</slot>
  </router-link>
</template>

<script>
  export default {
    name: 'sorter',

    props: {
      by: {
        type: String
      },
      asDefault: {
        default: false
      }
    },

    computed: {
      currentOrder() {
        const { order } = this.$route.query;

        if (!order) {
          return {
            by: this.asDefault ? this.by : null,
            direction: this.asDefault || null
          };
        }

        const parts = order.split(' ');

        return {
          by: parts.slice(0, -1).join(' '),
          direction: parts[parts.length - 1].toLowerCase()
        };
      },

      active() {
        return this.currentOrder.by === this.by;
      },

      ascending() {
        return this.active && this.currentOrder.direction === 'asc';
      },

      descending() {
        return this.active && this.currentOrder.direction === 'desc';
      },

      direction() {
        return this.active ? this.currentOrder.direction : null;
      },

      link() {
        const direction = this.active && this.currentOrder.direction === 'asc' ? 'desc' : 'asc';

        if (this.asDefault === direction) {
          return {
            query: {
              ...this.$route.query,
              order: void 0
            }
          };
        }

        return {
          query: {
            ...this.$route.query,
            order: `${this.by} ${direction}`
          }
        };
      }
    }

  };
</script>

