import moment from 'moment';

export function getDeadlineInThisMonth(bill) {
  const today = moment.utc();

  return moment
    .utc(bill.deadline || new Date())
    .month(today.month())
    .year(today.year())
    .toISOString();
}
