<template>
  <SubmitModal ref="modal" :submit="confirm">
    <template slot="cancel" slot-scope="{ item }">{{ item.no }}</template>
    <template slot="header" slot-scope="{ item }">{{ item.header }}</template>
    <template slot="submit" slot-scope="{ item }">{{ item.yes }}</template>
    <div slot="body" slot-scope="{ item }"><p>{{ item.body }}</p></div>
  </SubmitModal>
</template>

<script>
  import SubmitModal from './SubmitModal';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
    },

    methods: {
      show(props) {
        return this.$refs.modal.show({
          no: props.no || 'Nie',
          yes: props.yes || 'Tak',
          header: props.header || 'Uwaga!',
          body: props.body || 'Na pewno chcesz to zrobić?'
        });
      },

      confirm() {
        return true;
      }
    }
  };
</script>
