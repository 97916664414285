<template>
  <div class="pagination">
    <div class="pagination-prev">
      <router-link v-if="currentPage > 1"
                   class="button pagination-link-previous"
                   :to="linkTo(currentPage - 1)">
        <i class="fa fa-angle-left"></i>
      </router-link>

      <router-link v-if="currentPage - onSides > 1"
                   class="button pagination-link-first"
                   :to="linkTo(1)">
        1
      </router-link>

      <span v-if="currentPage - onSides > 2">...</span>

      <router-link v-for="page in prevPages"
                   :key="page"
                   class="button pagination-link-preceding"
                   :to="linkTo(page)">
        {{ page}}
      </router-link>
    </div>

    <input v-auto-select type="text" v-model.lazy="currentPage">

    <div class="pagination-next">
      <router-link v-for="page in nextPages"
                   :key="page"
                   class="button pagination-link-inferior"
                   :to="linkTo(page)">
        {{ page}}
      </router-link>

      <span v-if="currentPage + onSides < totalPages - 1">...</span>

      <router-link v-if="currentPage + onSides < totalPages"
                   class="button pagination-link-last"
                   :to="linkTo(totalPages)">
        {{ totalPages }}
      </router-link>

      <router-link v-if="currentPage < totalPages"
                   class="button pagination-link-next"
                   :to="linkTo(currentPage + 1)">
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'pagination',

    props: {
      onSides: {
        default: 2
      },
      meta: {
        default() {
          return {
            total: 0,
            offset: 0,
            limit: 50
          };
        }
      }
    },

    computed: {
      totalPages() {
        if (!this.meta || !this.meta.total || !this.meta.limit) {
          return 0;
        }

        return Math.ceil(this.meta.total / this.meta.limit);
      },

      currentPage: {
        get() {
          if (!this.totalPages) {
            return 0;
          }

          return Math.floor(this.meta.offset / this.meta.limit) + 1;
        },

        set(page) {
          this.$router.push(this.linkTo(Math.min(page, this.totalPages)));
        }
      },

      prevPages() {
        const start = Math.max(this.currentPage - this.onSides, 1);

        if (start >= this.currentPage) {
          return [];
        }

        return Array.from({
          length: this.currentPage - start
        }, (ignore, index) => start + index);
      },

      nextPages() {
        const end = Math.min(this.currentPage + this.onSides, this.totalPages);

        if (end <= this.currentPage) {
          return [];
        }

        return Array.from({
          length: end - this.currentPage
        }, (ignore, index) => this.currentPage + index + 1);
      }
    },

    methods: {
      linkTo(page) {
        return {
          query: Object.assign({}, this.$route.query, {
            page: page > 1 ? page : void 0
          })
        };
      }
    }
  };
</script>

<style lang="scss">
  @import "Pagination.scss";
</style>
