<template>
  <SubmitModal ref="modal" :submit="remove">
    <template slot="header">Uwaga!</template>
    <template slot="cancel">Nie</template>
    <template slot="submit">Tak, usuń</template>
    <div slot="body" slot-scope="{ item }">
      <p>Czy na pewno chcesz usunąć tego odbiorcę?</p>

      <div class="expense-wrapper">
        <BillSummary :bill="item"/>
      </div>
    </div>
  </SubmitModal>
</template>

<script>
  import BillSummary from '@components/Bills/BillSummary';
  import SubmitModal from '@components/Modal/SubmitModal';

  export default {
    inject: [
      '$api'
    ],

    components: {
      SubmitModal,
      BillSummary,
    },

    methods: {
      show(item) {
        return this.$refs.modal.show(item);
      },

      remove(item) {
        return this.$api.deleteRecipient(item.id);
      }
    }
  };
</script>
