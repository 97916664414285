<template>
  <ListItem class="shop-item" :to="`receipts?shopId=${shop.id}`">
    <template slot="summary">
      <ShopSummary :shop="shop"/>
    </template>

    <template slot="actions">
      <QuickNewItemAction @click.native="newReceipt" title="Nowy szybki paragon"/>
      <NewItemAction @click.native="goToNewReceipt" title="Nowy paragon"/>
      <EditItemAction @click.native="editShop"/>
      <RemoveItemAction @click.native="removeShop"/>
    </template>
  </ListItem>
</template>

<script>
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import ShopSummary from '@components/Shops/ShopSummary';
  import ListItem from '@components/List/ListItem';
  import NewItemAction from '@components/List/NewItemAction';
  import QuickNewItemAction from '@components/List/QuickNewItemAction';
  import { Modals } from '@components/consts';

  export default {
    components: {
      QuickNewItemAction,
      NewItemAction,
      RemoveItemAction,
      EditItemAction,
      ShopSummary,
      ListItem
    },

    inject: [
      'showModal',
      'showSuccessMessage'
    ],

    props: ['shop'],

    computed: {
      address() {
        if (!this.shop) {
          return '';
        }

        const shop = this.shop;

        if (shop.address && shop.city) {
          return `${shop.address}, ${shop.city}`;
        }

        return shop.address || shop.city;
      }
    },

    methods: {
      goToNewReceipt() {
        this.$router.push(`/receipts/new?shopId=${this.shop.id}`);
      },

      newReceipt() {
        this.showModal(Modals.RECEIPT_FORM, {
          shopId: this.shop.id
        })
          .then((receipt) => {
            if (receipt) {
              this.showSuccessMessage(`Dodano paragon w ${receipt.shop.name} na ${receipt.total} zł.`, `/receipts/${receipt.id}`);
              this.$emit('invalidate');
            }
          })
      },

      editShop() {
        this.showModal(Modals.SHOP_FORM, this.shop)
          .then((result) => {
            if (result) {
              Object.assign(this.shop, result);
              this.showSuccessMessage(`Zaktualizowano sklep ${this.shop.name}`);
              this.$emit('update', result);
            }
          });
      },

      removeShop() {
        this.showModal(Modals.SHOP_DELETE_CONFIRM, this.shop)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Usunięto sklep ${this.shop.name}`);
              this.$emit('remove', result);
            }
          });
      }
    }
  };
</script>
