<template>
  <div class="tag-summary">
    <ItemSummary
      :amount="tag.total"
      :quantity="`${tag.count} produktów`"
    >
      <AssignedTagsList slot="name" :tags="[tag]" :can-edit="false" :can-remove="false"/>
    </ItemSummary>
  </div>
</template>

<script>
  import ItemSummary from '../List/ItemSummary';
  import AssignedTagsList from '@components/Tags/AssignedTagsList';

  export default {
    components: { AssignedTagsList, ItemSummary },
    props: ['tag']
  };
</script>
