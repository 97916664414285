<template>
  <page :loading="loading">
    <h1 slot="header">Sklepy</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
      <button type="button" @click="addShop">Nowy sklep</button>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/receipts">Paragony</RelatedItem>
        <RelatedItem to="/tags">Tagi</RelatedItem>
      </Related>

      <div class="summary-section">
        <h3>Sortuj</h3>

        <sorter by="created" as-default="asc">Data utworzenia</sorter>
        <sorter by="receiptTotal">Suma paragonów</sorter>
        <sorter by="receiptCount">Liczba paragonów</sorter>
        <sorter by="name">Nazwa</sorter>
        <sorter by="address">Adres</sorter>
      </div>

      <div class="summary-section">
        <h3>Filtruj</h3>

        <ul>
          <li class="summary-item" :class="{ active: $route.query.city === undefined}">
            <router-link :to="link({ city: undefined })">Wszystkie</router-link>
            <span class="count">{{ shopsList.length }}</span>
          </li>
          <li class="summary-item" v-for="(count, city) in cities" :class="{ active: $route.query.city === city }">
            <router-link :to="link({ city })">{{ city || '(Bez przypisania)' }}</router-link>
            <span class="count">{{ count }}</span>
          </li>
        </ul>
      </div>
    </template>

    <template slot="body" v-if="loadingShops">
      <loader/>
    </template>

    <template slot="body" v-else>
      <pagination :meta="shopsMeta"/>
      <shop-item v-for="s in shops"
                 :key="s.id"
                 :shop="s"
                 @invalidate="updateShops()"
                 @remove="updateShops()"
      />
      <pagination :meta="shopsMeta"/>
    </template>
  </page>
</template>

<script>
  import Page from '../Page';
  import Loader from '../Loader';
  import Pagination from '../Pagination/Pagination';
  import ShopItem from './ShopItem';
  import Sorter from '../Sorter/Sorter';

  import {
    MessageMixin,
    ShopListMixin,
  } from '../../mixins';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';

  export default {
    name: 'shops-list',

    inject: [
      '$api',
      'showModal'
    ],

    mixins: [
      MessageMixin,
      ShopListMixin,
    ],

    components: {
      RelatedItem,
      Related,
      Page,
      Pagination,
      Loader,
      ShopItem,
      Sorter
    },

    data() {
      return {
        query: this.$route.query.q,
        page: this.$route.query.page,
        limit: 50,
        order: this.$route.query.order,
        shops: [],
        shopId: null,
        shopsMeta: null,
        loadingShops: false,
        loading: false,
        saving: false
      };
    },

    computed: {
      asideTitle() {
        return this.shop && this.shop.id ? 'Edytuj sklep' : 'Nowy sklep';
      },

      asideButton() {
        return this.shop && this.shop.id ? 'Zapisz' : 'Dodaj';
      },

      cities() {
        if (!this.shopsList) {
          return [];
        }

        return this.shopsList.reduce((cities, shop) => {
          if (!cities[shop.city]) {
            cities[shop.city] = 1;
          } else {
            cities[shop.city] += 1;
          }

          return cities;
        }, {});
      },

      q: {
        get() {
          return this.query;
        },

        set(val) {
          this.$router.push(this.link({
            q: val || void 0
          }));
        }
      },

      shop: {
        get() {
          if (this.shopId) {
            const selectedShop = this.shopsList.find((shop) => shop.id === Number(this.shopId));

            if (selectedShop) {
              return selectedShop;
            }
          }

          return {
            id: null,
            name: '',
            address: '',
            city: 'Poznań'
          };
        },

        set(val) {
          this.shopId = val ? val.id : null;
        }
      }
    },

    watch: {
      $route(to) {
        if (to.query.shopId !== this.shopId) {
          this.shopId = Number(to.query.shopId) || null;
        }

        if (to.query.page !== this.page) {
          this.page = Math.max(Number(to.query.page), 1) || null;
        }

        if (to.query.order !== this.order) {
          this.order = to.query.order;
        }

        if (to.query.q !== this.query) {
          this.query = to.query.q;
        }
      },

      '$route.query.city'() {
        this.reloadShops();
      },

      query() {
        this.reloadShops();
      },

      order() {
        this.reloadShops();
      },

      page() {
        this.reloadShops();
      }
    },

    created() {
      this.loading = true;

      Promise.all([
        this.updateShops(),
        this.updateShopsList()
      ])
        .catch((err) => this.showFailMessage(err.message))
        .then(() => (this.loading = false));

      this
        .$on('shop-failed-to-delete', (err) => this.showFailMessage(err.message))
        .$on('shop-deleted', (shop) => {
          this.showSuccessMessage(`Skasowano sklep ${shop.name}`);

          const index = this.shops.findIndex((s) => s.id === shop.id);

          if (index >= 0) {
            this.shops.splice(index, 1);
          }
        });
    },

    methods: {
      reloadShops() {
        this.loadingShops = true;
        this.updateShops()
          .catch((err) => this.showFailMessage(err.message))
          .then(() => (this.loadingShops = false));
      },

      updateShops() {
        return this.$api.getShops({
          q: this.query,
          city: this.$route.query.city,
          limit: this.limit,
          order: this.order,
          offset: this.page ? (this.page - 1) * this.limit : 0
        })
          .then(({ items, meta }) => {
            this.shops = items;
            this.shopsMeta = meta;
          });
      },

      link(query) {
        return {
          query: {
            ...this.$route.query,
            ...query
          }
        };
      },

      addShop() {
        this.showModal(Modals.SHOP_FORM)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano sklep ${result.name}`);
              this.reloadShops();
            }
          })
      }
    }
  };
</script>
