<template>
  <div class="auto-complete" :class="{ active }" @keydown="handleFocus($event)">
    <input class="auto-complete-input"
           ref="input"
           type="text"
           v-auto-select
           @input="query = $event.target.value"
           v-focus="autoFocus"
           :value="query"
           :placeholder="placeholder"
           @focus="activate()"
           @blur="deactivate()">
    <ul ref="list" class="auto-complete-list" v-show="active && filteredItems.length">
      <component v-for="(item, index) in filteredItems"
                 :key="index"
                 :is="itemCls"
                 :item="item"
                 :class="{ active: index === selected }"
                 @click.native="select(item)"
                 @mousedown.native.prevent />
    </ul>
  </div>
</template>

<style lang="scss" src="./AutoComplete.scss" />

<script>
  import AutoCompleteItem from './AutoCompleteItem';
  import { KeyCodes } from '@/consts';

  const LayoutModifiers = {
    BORDER_OVERSPAN: 3,
    MARGIN: 20,
    BORDER: 2
  };

  function slugize(val) {
    if (!val) {
      return '';
    }

    return val
      .toLowerCase()
      .replace(/[.,:;/\\-]/g, ' ')
      .replace(/[ ]+/g, ' ');
  }

  function getDimensions(element) {
    if (!element || element === window) {
      return document.body.getBoundingClientRect();
    }

    return element.getBoundingClientRect();
  }

  export default {
    name: 'auto-complete',
    props: {
      container: {
        default: null
      },
      placeholder: {
        default: ''
      },
      allowNotFound: {
        default: true
      },
      limit: {
        type: Number,
        default: 50
      },
      selectedItem: {
        type: Object,
        default: null
      },
      items: {
        type: Array,
        default: () => []
      },
      itemCls: {
        type: Object,
        default: () => AutoCompleteItem
      },
      selectFirst: {
        type: Boolean,
        default: false
      },
      autoFocus: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      if (!this.outlet) {
        const modalEl = this.$el.closest('.modal-body');

        if (modalEl) {
          this.outlet = modalEl;
        }
      }
    },

    data() {
      return {
        outlet: null,
        query: this.selectedItem ? this.selectedItem.label : '',
        selected: -1,
        active: false
      };
    },

    watch: {
      selectedItem(newItem) {
        if (!newItem) {
          this.query = '';

          return;
        }

        if (newItem.label === this.query) {
          return;
        }

        this.query = newItem.label;
      },

      container(value) {
        this.outlet = value;
      },

      filteredItems() {
        if (!this.filteredItems.length) {
          this.selected = -1;
        } else if (
          (this.selected === -1 && this.selectFirst) ||
          this.selected >= this.filteredItems.length
        ) {
          this.selected = 0;
        }
      }
    },

    computed: {
      slugQuery() {
        return slugize(this.query);
      },

      preparedItems() {
        return this.items
          .map((item) => ({
            ...item,
            slug: slugize(item.label)
          }));
      },

      filteredItems() {
        return this.preparedItems
          .filter((item) => ~item.slug.indexOf(this.slugQuery))
          .sort((itemA, itemB) => itemB.order - itemA.order)
          .slice(0, this.limit);
      },

      exactMatch() {
        return this.preparedItems.find((item) => this.slugQuery === item.slug);
      }
    },

    methods: {
      activate() {
        if (this.active) {
          return;
        }

        this.active = true;
        this.selected = this.filteredItems.length && this.selectFirst ? 0 : -1;
        this.updateDimensions();
      },

      deactivate() {
        this.selected = -1;
        this.active = false;
      },

      updateDimensions() {
        const inputRect = this.$refs.input.getBoundingClientRect();
        const outletRect = getDimensions(this.outlet);

        const heightAbove = inputRect.top - outletRect.top;
        const heightBelow = outletRect.bottom - inputRect.bottom;

        const shift = `${inputRect.height + LayoutModifiers.BORDER}px`;

        this.$refs.list.style.bottom = heightAbove > heightBelow ? shift : '';
        this.$refs.list.style.top = heightAbove > heightBelow ? '' : shift;
        this.$refs.list.style.maxHeight = `${Math.max(heightAbove, heightBelow) - LayoutModifiers.MARGIN}px`;
      },

      select(item, event) {
        if (!item) {
          if (this.allowNotFound) {
            this.$emit('select-new', this.query, event);
          } else {
            this.$emit('not-found', this.query, event);
            this.query = '';
          }

          return;
        }

        if (
          this.selectedItem &&
          item.label === this.selectedItem.label &&
          item.label === this.query
        ) {
          this.deactivate();
          return;
        }

        this.query = item.label;
        this.deactivate();
        this.$emit('select', item.data, event);
      },

      moveSelected(diff) {
        const count = this.filteredItems.length;

        if (!count) {
          return;
        }

        this.selected = (this.selected + count + diff) % count;
        this.adjustFocus();
      },

      adjustFocus() {
        const {
          clientHeight: wrapperHeight,
          scrollTop: wrapperTop
        } = this.$refs.list;
        const {
          clientHeight: itemHeight,
          offsetTop: itemTop
        } = this.$children[this.selected].$el;

        if (itemTop + itemHeight > wrapperHeight + wrapperTop) {
          this.$refs.list.scrollTop = itemTop + itemHeight - wrapperHeight;
        } else if (itemTop < wrapperTop) {
          this.$refs.list.scrollTop = itemTop;
        }
      },

      focus() {
        this.$refs.input.focus();
      },

      handleFocus(event) {
        let handled = false;

        switch (event.keyCode) {
          case KeyCodes.DOWN:
            this.moveSelected(1);
            handled = true;
            break;
          case KeyCodes.UP:
            this.moveSelected(-1);
            handled = true;
            break;
          case KeyCodes.ENTER:
          case KeyCodes.TAB:
            if (this.active && this.selected >= 0) {
              if (this.query !== this.filteredItems[this.selected].label) {
                handled = true;
              }

              this.select(this.filteredItems[this.selected], event);
            }

            if (!this.active) {
              break;
            }

            if (this.query === '') {
              this.$emit('select-empty', event);
            } else {
              this.select(this.exactMatch, event);
            }

            if (event.keyCode === KeyCodes.ENTER) {
              handled = true;
            }

            break;
          case KeyCodes.ESC:
            if (this.active) {
              this.deactivate();

              if (this.exactMatch && this.query !== this.exactMatch.label) {
                this.select(this.exactMatch);
              }  else if (!this.exactMatch) {
                if (this.allowNotFound) {
                  this.$emit('select', null);
                } else {
                  this.$emit('not-found', this.query, event);
                }
              }

              handled = true;
            } else {
              return;
            }
            break;
        }

        if (handled) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          this.activate();
        }
      }
    }
  };

</script>
