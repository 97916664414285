<template>
  <Page :loading="loading">
    <h1 slot="header">Dawcy</h1>

    <template slot="actions">
      <input type="text" v-model.lazy="q" placeholder="szukaj"/>
      <button type="button" @click="addPayer">Nowy dawca</button>
    </template>

    <template slot="aside">
      <Related>
        <RelatedItem to="/income">Wpływy</RelatedItem>
      </Related>

      <div class="summary-section">
        <h3>Sortuj</h3>

        <Sorter by="name">Nazwa</Sorter>
        <Sorter by="created" as-default="asc">Data utworzenia</Sorter>
        <Sorter by="defaultIncome">Kwota</Sorter>
      </div>
    </template>

    <template slot="body" v-if="loadingItems">
      <Loader/>
    </template>

    <template slot="body" v-else>
      <List :items="items" :meta="meta">
        <PayerItem slot-scope="{ item }" :payer="item" @remove="load()"/>
      </List>
    </template>
  </Page>
</template>

<script>
  import { ListMixin } from '@mixins';
  import List from '@components/List/List';
  import Loader from '@components/Loader';
  import Page from '@components/Page';
  import Sorter from '@components/Sorter/Sorter';
  import PayerItem from '@components/Income/Payers/PayerItem';
  import { Modals } from '@components/consts';
  import Related from '@components/Aside/Related';
  import RelatedItem from '@components/Aside/RelatedItem';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    mixins: [
      // BillsMixin,
      ListMixin
    ],

    components: {
      RelatedItem,
      Related,
      PayerItem,
      Sorter,
      Page,
      List,
      Loader
    },

    methods: {
      addPayer() {
        this.showModal(Modals.PAYER_FORM, {})
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano dawcę ${result.name}`);
              this.load();
            }
          })
      },

      fetch(query) {
        if (!query.order) {
          query.order = 'name desc';
        }

        return this.$api.getPayers(query);
      }
    }
  };
</script>
