module.exports = {
  inject: [
    '$api'
  ],

  data() {
    return {
      productsList: []
    };
  },

  methods: {
    updateProductsList() {
      return this.$api.getProductsList()
        .then((products) => {
          this.productsList = products;

          return products;
        }, () => {
          this.productsList = [];
        });
    }
  }
};
