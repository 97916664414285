import ShopInput from '../components/ShopInput/ShopInput';

export default {
  components: {
    ShopInput
  },

  inject: [
    '$api'
  ],

  data() {
    return {
      shopId: null,
      shopsList: []
    };
  },

  computed: {
    shop: {
      set(shop) {
        this.shopId = shop ? shop.id : null;
      },
      get() {
        return this.shopsList.find((shop) => shop.id === this.shopId);
      }
    }
  },

  methods: {
    updateShopsList() {
      return this.$api.getShopsList()
        .then((shops) => {
          this.shopsList = shops;

          return shops;
        }, () => {
          this.shopsList = [];
        });
    }
  }
};
