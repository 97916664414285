<template>
  <div class="product-item">
    <div class="product-item-details">
      <auto-complete ref="productName"
                     class="product-name"
                     :autoFocus="autoFocus"
                     :class="{ error: errors.name }"
                     :selected-item="selectedItem"
                     :items="items"
                     placeholder="Nazwa produktu"
                     @select="select"
                     @select-new="create"
      />
      <div class="product-cost">
        <input type="number"
               step="0.01"
               :class="{ error: errors.cost }"
               v-auto-select
               v-model="rawCost"
        >
      </div>
      <div class="product-quantity">
        <input type="number"
               step="0.001"
               :class="{ error: errors.quantity }"
               v-auto-select
               v-model="rawQuantity"
        >
      </div>
    </div>

    <div class="product-item-summary">
      <tag-input class="product-tags" :tags="tags" @change="product.tagList = arguments[0]" @keydown.native.tab="next($event)"/>
      <div class="product-total">Razem: {{ total }} zł <a href="#remove" @click.prevent="remove" tabindex="100">Usuń</a></div>
    </div>
  </div>
</template>

<script>
  import AutoComplete from '../AutoComplete/AutoComplete';
  import toNumber from '../../utils/toNumber';
  import TagInput from '../TagInput/TagInput.vue';

  function toAutoCompleteItem(product) {
    return {
      data: product,
      label: product.name,
      order: product.occurence
    };
  }

  export default {
    name: 'product-item',
    components: {
      TagInput,
      AutoComplete
    },

    props: {
      autoFocus: {
        default: false
      },
      showErrors: {
        default: false
      },
      product: {
        default: {
          name: 'Unknown',
          cost: 0,
          quantity: 1,
          tags: ''
        }
      },
      list: {
        type: Array,
        default: []
      }
    },

    data() {
      return {
        rawCost: (this.product && this.product.cost) || 0,
        rawQuantity: (this.product && this.product.quantity) || 1
      };
    },

    watch: {
      product(newProduct) {
        this.product.name = newProduct.name;
        this.product.cost = newProduct.cost;
        this.product.quantity = newProduct.quantity;
        this.rawCost = newProduct.cost;
        this.rawQuantity = newProduct.quantity;
      },

      rawCost(val) {
        this.product.cost = toNumber(val);
      },

      rawQuantity(val) {
        this.product.quantity = toNumber(val);
      }
    },

    computed: {
      total() {
        return (this.product.cost * this.product.quantity).toFixed(2);
      },

      errors() {
        return {
          name: this.showErrors && !this.product.name,
          cost: this.showErrors && !this.product.cost,
          quantity: this.showErrors && !this.product.quantity
        };
      },

      items() {
        return this.list.map(toAutoCompleteItem);
      },

      selectedItem() {
        return this.product ? toAutoCompleteItem(this.product) : null;
      },

      tags() {
        return this.product.tagList;
      }
    },

    methods: {
      create(name) {
        this.product.name = name;
      },

      select(product) {
        this.product.name = product.name;
      },

      focus() {
        this.$refs.productName.focus();
      },

      next(event) {
        if (event.shiftKey) {
          return;
        }

        this.$emit('next', event);
      },

      remove() {
        this.$emit('remove', this.product);
      }
    }
  };

</script>

<style lang="scss">
  @import "./ProductItem.scss";
</style>
