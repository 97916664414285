import Dashboard from '../views/Dashboard';
import Placeholder from '../views/Placeholder';
import Login from '../views/Login';

import ShopsList from '@components/Shops/ShopsList';
import NewReceipt from '@components/NewReceipt/NewReceipt';
import EditReceipt from '@components/EditReceipt/EditReceipt';
import ReceiptsList from '@components/ReceiptsList/ReceiptsList';
import BillsList from '@components/Bills/BillsList';
import RecipientsList from '@components/Bills/RecipientsList';
import IncomeList from '@components/Income/IncomeList';
import PayersList from '@components/Income/Payers/PayersList';
import ProductList from '@components/Products/ProductList';
import TagList from '@components/Tags/TagList';

export const Map = {
  LOGIN: {
    path: '/',
    title: 'Logowanie'
  },
  HOME: {
    path: '/dashboard',
    title: 'Start'
  },
  RECEIPTS: {
    path: '/receipts',
    title: 'Paragony'
  },
  SHOPS: {
    path: '/shops',
    title: 'Sklepy'
  },
  PRODUCTS: {
    path: '/products',
    title: 'Produkty'
  },
  TAGS: {
    path: '/tags',
    title: 'Tagi'
  },
  BILLS: {
    path: '/bills',
    title: 'Rachunki'
  },
  INCOME: {
    path: '/income',
    title: 'Wpływy'
  }
};

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

export default [{
  path: Map.LOGIN.path,
  meta: {
    title: Map.LOGIN.title
  },
  component: Login
}, {
  path: Map.HOME.path,
  meta: {
    title: Map.HOME.title
  },
  component: Dashboard
}, {
  path: Map.RECEIPTS.path,
  component: Placeholder,
  children: [
    {
      path: '',
      component: ReceiptsList,
      meta: {
        title: Map.RECEIPTS.title
      }
    },
    {
      path: 'new',
      component: NewReceipt,
      meta: {
        title: 'Nowy paragon'
      }
    },
    {
      path: ':id',
      component: EditReceipt,
      meta: {
        title: 'Edycja paragonu'
      }
    }
  ]
}, {
  path: Map.SHOPS.path,
  component: Placeholder,
  children: [
    {
      path: '',
      component: ShopsList,
      meta: {
        title: Map.SHOPS.title
      }
    }
  ]
}, {
  path: Map.BILLS.path,
  component: Placeholder,
  children: [
    {
      path: '',
      component: BillsList,
      meta: {
        title: Map.BILLS.title
      }
    },
    {
      path: 'recipients',
      component: RecipientsList,
      meta: {
        title: 'Odbiorcy rachunków'
      }
    }
  ]
}, {
  path: Map.INCOME.path,
  component: Placeholder,
  children: [
    {
      path: '',
      component: IncomeList,
      meta: {
        title: Map.INCOME.title
      }
    },
    {
      path: 'payers',
      component: PayersList,
      meta: {
        title: 'Dawcy'
      }
    }
  ]
}, {
  path: Map.PRODUCTS.path,
  component: Placeholder,
  children: [
    { path: '', component: ProductList, meta: { title: Map.PRODUCTS.title }}
  ]
}, {
  path: Map.TAGS.path,
  component: Placeholder,
  children: [
    { path: '', component: TagList, meta: { title: Map.TAGS.title }}
  ]
}];
