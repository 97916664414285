import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import api from './api';
import './directives/focus';
import './directives/select';
import './filters/currency';
import './filters/moment';

Vue.config.productionTip = false;

new Vue({
  provide: {
    $api: api
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app');
