<template>
  <div class="tag-list">
    <div class="tag-list__item" v-for="tag in tags" :key="tag.id" :style="getTagStyle(tag)">
      <router-link v-if="canSeeDetails" class="tag-list__item-name" :to="`/receipts?tagId=${tag.id}`" title="Zobacz paragony przypisane do tagu">{{ tag.name }}</router-link>
      <span v-else class="tag-list__item-name">{{ tag.name }}</span>
      <Icon v-if="canEdit" icon="pencil" @click.stop.prevent="editTag(tag)" title="Edytuj tag" />
      <Icon v-if="canRemove" icon="trash-o" @click.stop.prevent="removeTag(tag)" title="Usuń tag" />
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/variables.scss";

.tag-list {
  display: flex;

  $padding: 0.3em;

  &__item {
    border-radius: 0.3em;
    border: 2px solid $mainColor;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;

    &-name, .icon {
      text-decoration: none;
      padding: $padding;
      color: inherit;

      &:hover {
        background: white;
        color: $regularColor
      }
    }
  }
}
</style>

<script>
import Icon from '@components/Icon';
import { Modals } from '@components/consts';

export default {
  components: { Icon },

  inject: [
    'showModal',
    'showSuccessMessage',
    'showFailMessage',
  ],

  props: {
    tags: {
      type: Array,
      default() {
        return [];
      }
    },
    canSeeDetails: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    canRemove: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    editTag(tag) {
      this.showModal(Modals.TAG_FORM, tag)
        .then((result) => {
          if (result) {
            Object.assign(tag, result);
            this.showSuccessMessage(`Zaktualizowano tag ${result.name}`);
          }
        });
    },

    removeTag(tag) {
      this.showFailMessage('Not implemented');
      console.log('Not yet implemented', tag);
    },

    getTagStyle(tag) {
      return {
        cursor: this.canSeeDetails ? 'pointer' : 'default',
        background: tag.backgroundColor,
        color: tag.color
      };
    }
  }
};
</script>
