<template>
  <div class="expense-entry">
    <div class="expense-details">
      <strong>{{ bill.name }}</strong>
      <small>{{ bill.description }}</small>
    </div>

    <div class="expense-total">
      <strong>{{ cost }} zł</strong>
      <small>{{ deadline }}</small>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: ['bill'],

    computed: {
      cost() {
        return this.$options.filters.currency((this.bill && this.bill.cost) || 0);
      },

      deadline() {
        return moment.utc(this.bill.deadline).format('LL');
      }
    }
  };
</script>
