<template>
  <div class="list-item-select list-item-interactive">
    <input type="checkbox" class="list-item-checkbox"/>
    <span class="list-item-label fa" :class="icon"></span>
  </div>
</template>

<script>
  export default {
    props: ['selected'],
    computed: {
      icon() {
        return this.selected ? 'fa-check-square-o' : 'fa-square-o';
      }
    }
  };
</script>

<style lang="scss" scoped>
  .list-item-checkbox {
    display: none;
  }
</style>
