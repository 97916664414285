<template>
  <ListItemWithDetails class="bill-item" :class="{ paid: bill.status, unpaid: !bill.status }">
    <template slot="summary">
      <BillSummary :bill="bill"/>
    </template>

    <template slot="details">
      <BillDetails :bill="bill"/>
    </template>

    <template slot="actions">
      <ToggleItemAction :status="bill.status" @click.native="payBill" v-if="showPay" enable-label="Opłać" disable-label="Oznacz jako nieopłacone"/>
      <DuplicateAction @click.native="duplicate" v-if="showDuplicate"/>
      <RepeatAction @click.native="repeat" v-if="showRepeat"/>
      <EditItemAction @click.native="editBill" v-if="showEdit"/>
      <RemoveItemAction @click.native="removeBill" v-if="showDestroy"/>
    </template>
  </ListItemWithDetails>
</template>

<script>
  import ListItemWithDetails from '@components/List/ListItemWithDetails';
  import RemoveItemAction from '@components/List/RemoveItemAction';
  import EditItemAction from '@components/List/EditItemAction';
  import BillDetails from '@components/Bills/BillDetails';
  import BillSummary from '@components/Bills/BillSummary';
  import ToggleItemAction from '@components/List/ToggleItemAction';
  import DuplicateAction from '@components/List/DuplicateAction';
  import RepeatAction from '@components/List/RepeatAction';
  import { Modals } from '@components/consts';

  export default {
    inject: [
      '$api',
      'showModal',
      'showSuccessMessage'
    ],

    components: {
      DuplicateAction,
      ToggleItemAction,
      BillSummary,
      BillDetails,
      ListItemWithDetails,
      RemoveItemAction,
      EditItemAction,
      RepeatAction
    },

    props: {
      bill: {
        type: Object
      },
      showDestroy: {
        type: Boolean,
        default: true
      },
      showDuplicate: {
        type: Boolean,
        default: true
      },
      showRepeat: {
        type: Boolean,
        default: true
      },
      showPay: {
        type: Boolean,
        default: true
      },
      showEdit: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      payBill() {
        this.$api.saveBill({
          id: this.bill.id,
          status: !this.bill.status
        })
          .then((bill) => {
            this.bill.status = bill.status;

            const message = bill.status ? `Opłacono rachunek ${bill.name}` : `Rachunek ${bill.name} nie jest już opłacony`;

            this.showSuccessMessage(message);
            this.$emit('paid', bill);
          }, (err) => this.$emit('error', err));
      },

      duplicate() {
        this.showModal(Modals.BILL_FORM, {
          ...this.bill,
          status: false,
          id: null
        })
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Dodano rachunek ${result.name}`);
              this.$emit('invalidate');
            }
          });
      },

      repeat() {
        this.showModal(Modals.REPEAT_BILL, this.bill)
          .then((result) => {
            if (result) {
              this.showSuccessMessage('Dodano rachunki');
              this.$emit('invalidate');
            }
          });
      },

      editBill() {
        this.showModal(Modals.BILL_FORM, this.bill)
          .then((result) => {
            if (result) {
              Object.assign(this.bill, result);
              this.showSuccessMessage(`Zaktualizowano rachunek ${this.bill.name}`);
              this.$emit('update', result);
            }
          });
      },

      removeBill() {
        this.showModal(Modals.BILL_DELETE_CONFIRM, this.bill)
          .then((result) => {
            if (result) {
              this.showSuccessMessage(`Usunięto rachunek ${this.bill.name}`);
              this.$emit('remove', result);
            }
          });
      }
    }
  };
</script>

<style lang="scss">
  .bill-item.paid strong {
    color: #005900;
  }

  .bill-item.unpaid strong {
    color: #900;
  }
</style>
