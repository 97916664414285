<template>
  <page>
    <h1 slot="header"></h1>

    <quick-receipt slot="aside"/>

    <div slot="body">
      <bills-to-pay/>
    </div>
  </page>
</template>

<script>
  import Page from '../components/Page';
  import QuickReceipt from '../components/QuickReceipt/QuickReceipt';
  import BillsToPay from '../components/Bills/BillsToPay';

  export default {
    name: 'dashboard',

    components: {
      QuickReceipt,
      BillsToPay,
      Page
    }
  };
</script>
